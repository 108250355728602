/* eslint-disable prettier/prettier */
import { Button, FormGroup, Label } from 'reactstrap';
import { CurrencyInputProps } from '../../typings';
import { CurrencySelector } from '../../../../shared/CurrencySelector';
import { currencyConverter } from '../../../../../helpers/utils';
import React, { ReactElement, useState } from 'react';
import Textbox from './Textbox';
import _ from 'lodash';

const defaultCurrency = { label: 'EUR', value: 'EUR' };

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  field,
  value,
  onChange,
  showLabel = true,
  disabled = false,
  setCurrency: setCurrencyProp,
  currency,
  currencyChangeObjFn,
}) => {
  const [touched, setTouched] = useState(false);

  const updateCurrency = (value: string) => {
    setCurrencyProp({ label: value, value: value });
  };

  const renderLabel = (): ReactElement | false => {
    if (showLabel) {
      return (
        <Label for="phone">
          <div>
            {field.label}
            {field.required ? <span className="text-danger">*</span> : false}
          </div>
        </Label>
      );
    }
    return false;
  };

  const currencySelect = () => {
    return (
      <CurrencySelector
        disabled={disabled}
        selected={currency}
        onChange={(value) => updateCurrency(value || '')}
      />
    );
  };

  const onConvertClick = async () => {
    const conversionRate = await currencyConverter(currency.value);
    setCurrencyProp(defaultCurrency);
    const valueInEUR = Number(value) * Number(conversionRate);
    onChange(valueInEUR.toFixed(2).toString(), field.key, true);
    if (
      typeof currencyChangeObjFn === 'function' &&
      (field.key === 'excessValue' ||
        field.key === 'jobCostEstimate' ||
        field.key === 'authorizedValue')
    ) {
      currencyChangeObjFn({
        key: field.key,
        valueInEUR: valueInEUR,
        conversionRate: conversionRate,
        currency: currency.value,
        value: Number(value),
      });
    }
  };

  const renderInput = () => {
    return (
      <>
        {currencySelect()}
        <Textbox
          disabled={disabled}
          showLabel={false}
          type="number"
          value={value}
          field={field}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setTouched(true);
            onChange(e, field.key, true);
          }}
        />
      </>
    );
  };

  const isValid = !_.isEmpty(value);
  const validationClass = isValid ? 'is-valid' : 'invalid';
  const formGroupClass = touched ? validationClass : '';

  return (
    <FormGroup className={`relative ${formGroupClass}`}>
      {renderLabel()}
      <div className="currency-input-form-input">
        {renderInput()}
        {currency?.value !== 'EUR' && (
          <Button
            color="secondary"
            outline
            disabled={currency?.value === 'EUR'}
            onClick={onConvertClick}>
            Convert
          </Button>
        )}
      </div>
    </FormGroup>
  );
};

export default CurrencyInput;
