/* eslint-disable prettier/prettier */
import { IClaimGroup, NewClaimFormProps } from '../typings';
import { Nav, TabContent } from 'reactstrap';
import ClaimFormSection from './ClaimFormSection';
import FormNavItem from './FormNavItem';
import React, { useState } from 'react';
import _ from 'lodash';

const NewClaimForm: React.FC<NewClaimFormProps> = ({ config, newClaim }) => {
  const [activeTab, setActiveTab] = useState('0');

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const next = () => {
    // Clamp activeTab between max tabs and increment
    const maxTab = _.size(config) - 1;
    const nextTab: number = Number(activeTab) + 1;
    if (nextTab > maxTab) {
      return false;
    }
    return () => {
      setActiveTab(String(nextTab));
    };
  };
  const prev = () => {
    // Clamp activeTab between max tabs and 0
    const minTab = 0;
    const prevTab: number = Number(activeTab) - 1;
    if (prevTab < minTab) {
      return false;
    }
    return () => {
      setActiveTab(String(prevTab));
    };
  };

  const invalidGroups = _.keys(newClaim.invalid);
  return (
    <div>
      <div>
        <h1 className="form-title">New Claim</h1>
        <p className="lead subtitle">
          Fields marked with <span className="text-danger">*</span> are required.
        </p>
      </div>
      <Nav tabs>
        {_.map(config, (group: IClaimGroup, index: number) => {
          return (
            <FormNavItem
              key={`nav-item-${index}`}
              group={group}
              valid={!_.includes(invalidGroups, group.key)}
              ready={!_.includes(invalidGroups, group.key) && _.includes(newClaim.valid, group.key)}
              id={index}
              toggle={toggle}
              active={activeTab === String(index)}
            />
          );
        })}
      </Nav>
      <TabContent activeTab={activeTab}>
        {_.map(config, (group: IClaimGroup, index: number) => {
          return (
            <ClaimFormSection
              key={`nav-item-${index}`}
              group={group}
              id={index}
              newClaim={newClaim}
              next={next()}
              prev={prev()}
            />
          );
        })}
      </TabContent>
    </div>
  );
};

export default NewClaimForm;
