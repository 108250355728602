/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';

export default class AppDefault extends Component {
  render() {
    return (
      <div>
        <div className="container fill-viewport">{this.props.children}</div>
      </div>
    );
  }
}
