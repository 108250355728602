/* eslint-disable prettier/prettier */
import { Provider } from 'react-redux';
import {
  ReactReduxFirebaseConfig,
  ReactReduxFirebaseProvider,
  getFirebase,
} from 'react-redux-firebase';
import { applyMiddleware, compose, createStore } from 'redux';
import { createFirestoreInstance, getFirestore } from 'redux-firestore';
import React from 'react';
import thunk from 'redux-thunk';

import { Router, Switch } from 'react-router-dom';
import ReduxPromise from 'redux-promise';
import reducers from './reducers';

import { firebase } from './config/firebase';
// Helpers
import RouteWithLayout from './helpers/RouteWithLayout';
import history from './helpers/history';

// Wrappers
import AppDefault from './wrappers/AppDefault';
import AppWithSidebar from './wrappers/AppWithSidebar';

import 'react-perfect-scrollbar/dist/css/styles.css';
import AllClaimsPage from './components/claims/allClaims/AllClaimsPage';
import CompletedClaimsPage from './components/claims/completed/CompletedClaimPage';
import DashboardPage from './components/dashboard/DashboardPage';
import IncomingClaimsPage from './components/claims/incoming/IncomingClaimPage';
import LoginPage from './components/auth/LoginPage';
import NewClaimsPage from './components/claims/new/NewClaimPage';
import NewsFeedPage from './components/newsFeed/newsFeedPage';
import OutgoingClaimsPage from './components/claims/outgoing/OutgoingClaimPage';
import UsersPage from './components/claims/users/UsersPage';

// Middlewares
const middlewares = [
  ReduxPromise,
  thunk.withExtraArgument({ getFirebase, getFirestore }),
];

const rrfConfig: Partial<ReactReduxFirebaseConfig> = {
  userProfile: 'users', // where profiles are stored in database
  useFirestoreForProfile: true,
};

// Create store with reducers and initial state
const initialState = {};
const store = createStore(
  reducers,
  initialState,
  compose(applyMiddleware(...middlewares))
);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

const App = () => {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Router history={history}>
          <Switch>
            <RouteWithLayout
              key="login"
              layout={AppDefault}
              path="/login"
              component={LoginPage}
            />
            <RouteWithLayout
              key="all"
              layout={AppWithSidebar}
              path="/all"
              component={AllClaimsPage}
              requiresAuth={true}
            />
            <RouteWithLayout
              key="users"
              layout={AppWithSidebar}
              path="/users"
              component={UsersPage}
              requiresAuth={true}
            />
            <RouteWithLayout
              key="new-claim"
              layout={AppWithSidebar}
              path="/new-claim"
              component={NewClaimsPage}
              requiresAuth={true}
            />
            <RouteWithLayout
              key="incoming"
              layout={AppWithSidebar}
              path="/incoming"
              component={IncomingClaimsPage}
              requiresAuth={true}
            />
            <RouteWithLayout
              key="outgoing"
              layout={AppWithSidebar}
              path="/outgoing"
              component={OutgoingClaimsPage}
              requiresAuth={true}
            />
            <RouteWithLayout
              key=" completed"
              layout={AppWithSidebar}
              path="/completed"
              component={CompletedClaimsPage}
              requiresAuth={true}
            />
             <RouteWithLayout
              key="/news-feed"
              layout={AppWithSidebar}
              path="/news-feed"
              component={NewsFeedPage}
              requiresAuth={true}
            />
            <RouteWithLayout
              key="/"
              layout={AppWithSidebar}
              path="/"
              component={DashboardPage}
              requiresAuth={true}
            />
          </Switch>
        </Router>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

export default App;
