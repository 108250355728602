/* eslint-disable prettier/prettier */
import { functions } from '../../config/firebase';

export const createUser = (
  email: string,
  partnerId: string,
  password: string,
  callback: Function
) => {
  return (dispatch: any) => {
    const callable = functions.httpsCallable('createUser');
    callable({ email, partnerId, password })
      .then((data) => {
        callback(data);
      })
      .catch((err) => {
        callback(err);
      });
  };
};

export const deleteUser = (id: string, callback: Function) => {
  return (dispatch: any) => {
    const callable = functions.httpsCallable('deleteUser');
    callable({ id })
      .then((data) => {
        callback(data);
      })
      .catch((err) => {
        callback(err);
      });
  };
};

export const resetUserPassword = (
  id: string,
  value: string,
  callback: Function
) => {
  return (dispatch: any) => {
    const callable = functions.httpsCallable('resetUserPassword');
    callable({ id, value })
      .then((data) => {
        callback(data);
      })
      .catch((err) => {
        callback(err);
      });
  };
};
