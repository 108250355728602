/* eslint-disable prettier/prettier */
import '../../../../../styles/DatePicker.scss';
import '../../../../../styles/global.scss';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DatePickerProps, DatePickerState } from '../../typings';
import { Label } from 'reactstrap';
import { SingleDatePicker, isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import React from 'react';
import moment from 'moment';

class DatePicker extends React.Component<DatePickerProps, DatePickerState> {
  constructor(props: any) {
    super(props);
    this.state = {
      focused: false,
      touched: false,
    };
    this.checkDateOptions = this.checkDateOptions.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.setTouched = this.setTouched.bind(this);
  }

  checkDateOptions(day: moment.Moment): boolean {
    const {
      field: { options },
    } = this.props;
    if (!options) {
      return false;
    }
    switch (options[0]) {
      case 'pastOnly':
        return !isInclusivelyBeforeDay(day, moment());
      case 'futureOnly':
        return !isInclusivelyAfterDay(day, moment());
      default:
        return false;
    }
  }

  setTouched(value: boolean): void {
    this.setState({ ...this.state, touched: value });
  }

  onDateChange(date: moment.Moment | null): void {
    this.setTouched(true);
    this.props.onChange(date);
  }

  render() {
    const { focused, touched } = this.state;
    const {
      value,
      field: { key, label, required },
      showLabel = true,
      disabled = false,
      withPortal = false,
    } = this.props;
    const isValid = touched === true;
    const validationClass = isValid ? 'is-valid' : 'invalid';
    const formGroupClass = touched ? validationClass : '';
    return (
      <div className={`date-picker ${formGroupClass}`}>
        {showLabel ? (
          <Label className="display-block">
            {label}
            {required ? <span className="text-danger">*</span> : false}
          </Label>
        ) : (
          false
        )}
        <SingleDatePicker
          numberOfMonths={1}
          isOutsideRange={this.checkDateOptions}
          disabled={disabled}
          date={moment(value)} // momentPropTypes.momentObj or null
          onDateChange={this.onDateChange} // PropTypes.func.isRequired
          focused={focused} // PropTypes.bool
          onFocusChange={({ focused }) => this.setState({ focused: focused || false })} // PropTypes.func.isRequired
          id={`datepicker-${key}`} // PropTypes.string.isRequired,
          hideKeyboardShortcutsPanel={true}
          withPortal={withPortal}
        />
      </div>
    );
  }
}

export default DatePicker;
