/* eslint-disable prettier/prettier */
import { Button, Table } from 'reactstrap';
import { CURRENCY_CHANGE_OBJ } from '../../../helpers/utils';
import { ClaimInfoEditProps, IClaimGroup, IClaimGroupField } from '../new/typings';
import { IClaim } from '../typings';
import { INewClaimPayload } from '../../../../../typings';
import { Icon } from 'react-icons-kit';
import { checkSquare } from 'react-icons-kit/feather';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { updateClaim } from '../../../actions/update-claim';
import AddressBox from '../new/ClaimForm/inputs/Address';
import CurrencyInput from '../new/ClaimForm/inputs/CurrencyInput';
import DatePicker from '../new/ClaimForm/inputs/DatePicker';
import DropDownControl from '../new/ClaimForm/inputs/Dropdown';
import EmailInput from '../new/ClaimForm/inputs/EmailInput';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PhoneNumber from '../new/ClaimForm/inputs/PhoneNumber';
import React, { ReactElement, useEffect, useState } from 'react';
import SwitchControl from '../new/ClaimForm/inputs/Switch';
import Textbox from '../new/ClaimForm/inputs/Textbox';
import Urgency from '../new/ClaimForm/inputs/Urgency';
import YearInput from '../new/ClaimForm/inputs/YearInput';
import _ from 'lodash';
import moment from 'moment';

const initialState: { saving: boolean; hasChanges: boolean } = {
  saving: false,
  hasChanges: false,
};

const defaultCurrency = { label: 'EUR', value: 'EUR' };

const currencyChangeObj = CURRENCY_CHANGE_OBJ;

const ClaimInfoEdit: React.FC<ClaimInfoEditProps> = ({
  queue,
  groups,
  claim,
  updateClaim,
  isDisabled,
  isCurrencyDisabled,
}) => {
  const claimSansAudit: INewClaimPayload = _.pick(claim, [
    'assign',
    'driver',
    'insurer',
    'repairInfo',
    'vehicle',
  ]);
  const [updatedClaim, updateClaimState] = useState({ ...claimSansAudit });
  const [state, updateState] = useState(initialState);
  const [excessCurrency, setExcessCurrency] = useState(defaultCurrency);
  const [jobCurrency, setJobCurrency] = useState(defaultCurrency);

  useEffect(() => {
    if (claim?.insurer?.claimNumber !== updatedClaim.insurer.claimNumber) {
      reset();
    }
    // we don't need exhaustive hook dep check, shut the linter up.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claim]);

  useEffect(() => {
    checkForChanges();
    // we don't need exhaustive hook dep check, shut the linter up.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedClaim]);

  const saveChanges = () => {
    updateState({ ...state, saving: true });
    updateClaim(String(claim.id), updatedClaim, currencyChangeObj, (err: Error) => {
      updateState({ ...initialState });
      if (err) {
        toast.error('Could not update claim. Please try again later');
        console.error(err);
        return;
      }
      const successMessage = (
        <div className="toast-message">
          <Icon icon={checkSquare} size={36} /> Successfully updated claim.
        </div>
      );
      return toast.success(successMessage, {
        autoClose: 3000,
        closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
      });
    });
  };

  const reset = () => {
    const claimSansAudit: INewClaimPayload = _.pick(claim, [
      'assign',
      'driver',
      'insurer',
      'repairInfo',
      'vehicle',
    ]);
    updateClaimState({
      ...updatedClaim,
      ...claimSansAudit,
    });
  };

  const checkForChanges = (): boolean => {
    const claimSansAudit: INewClaimPayload = _.pick(claim, [
      'assign',
      'driver',
      'insurer',
      'repairInfo',
      'vehicle',
    ]);
    const hasChanges = !_.isEqual(updatedClaim, claimSansAudit);
    updateState({ ...state, hasChanges });
    return hasChanges;
  };

  const currencyChangeObjFn = (obj: {
    key: 'authorizedValue' | 'excessValue' | 'jobCostEstimate';
    valueInEUR: number;
    conversionRate: number;
    currency: string;
    value: number;
  }) => {
    currencyChangeObj[obj.key] = {
      key: obj.key,
      value: Number(+obj.value.toFixed(2)),
      valueInEUR: Number(+obj.valueInEUR.toFixed(2)),
      conversionRate: obj.conversionRate,
      currency: obj.currency,
    };
  };

  const onChange = (
    value:
      | React.ChangeEvent<HTMLInputElement>
      | moment.Moment
      | string
      | boolean
      | undefined
      | null,
    key: string,
    group: IClaimGroup,
    isNumber = false,
  ): void => {
    if (_.isUndefined(value)) {
      console.error(`No value to change for ${String(group.key)}|${key}`);
      return;
    }
    let payload: string | boolean | number | null | Date = moment.isMoment(value)
      ? value.toDate()
      : _.isBoolean(value)
      ? value
      : _.isString(value)
      ? value
      : value
      ? value.target.value
      : null;
    if (isNumber) {
      payload = Number(payload);
      if (_.isNaN(payload)) {
        console.error(`Invalid value type for  ${String(group.key)}|${key}`);
      }
    }
    updateClaimState({
      ...updatedClaim,
      [group.key]: {
        ...updatedClaim[group.key],
        [key]: payload,
      },
    });
  };

  const renderInput = (
    field: IClaimGroupField,
    value: any,
    group: IClaimGroup,
  ): ReactElement | false => {
    let isEditable = false;
    if (!isDisabled) {
      isEditable = _.isArray(field.editable) && _.includes(field.editable, queue);
    }
    switch (field.control) {
      case 'email':
        return (
          <EmailInput
            disabled={!isEditable}
            showLabel={false}
            type="email"
            value={value || ''}
            field={field}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, field.key, group)}
          />
        );
      case 'string':
        // Text field
        return (
          <Textbox
            disabled={!isEditable}
            showLabel={false}
            type="text"
            value={value || ''}
            field={field}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, field.key, group)}
          />
        );
      case 'textarea':
        return (
          <Textbox
            disabled={!isEditable}
            showLabel={false}
            type="textarea"
            value={value || ''}
            field={field}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, field.key, group)}
          />
        );
      case 'phone':
        return (
          <PhoneNumber
            claim={claim}
            disabled={!isEditable}
            type="text"
            value={value}
            field={field}
            onChange={(phone) => onChange(phone, field.key, group)}
            showLabel={false}
          />
        );
      case 'number':
        return (
          <Textbox
            disabled={!isEditable}
            showLabel={false}
            type="number"
            value={value || ''}
            field={field}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e, field.key, group, true)
            }
          />
        );
      case 'currency':
        return (
          <CurrencyInput
            // disabled={isCurrencyDisabled || !isEditable}
            showLabel={false}
            setCurrency={(value) => {
              field.key === 'excessValue' ? setExcessCurrency(value) : setJobCurrency(value);
            }}
            currency={field.key === 'excessValue' ? excessCurrency : jobCurrency}
            type="number"
            value={value}
            field={field}
            onChange={(
              e:
                | React.ChangeEvent<HTMLInputElement>
                | moment.Moment
                | string
                | boolean
                | undefined
                | null,
              key: string,
              isNumber = false,
            ) => onChange(e, field.key, group, true)}
            currencyChangeObjFn={currencyChangeObjFn}
          />
        );
      case 'year':
        return (
          <YearInput
            disabled={!isEditable}
            showLabel={false}
            type="number"
            value={value}
            field={field}
            onChange={(year: string) => onChange(year, field.key, group, true)}
          />
        );
      case 'Timestamp':
        return (
          <DatePicker
            disabled={!isEditable}
            showLabel={false}
            field={field}
            value={value || moment()}
            onChange={(date: moment.Moment | null) => onChange(date, field.key, group)}
            withPortal={false}
          />
        );
      case 'display':
        return (
          <Urgency
            claim={{ claim: updatedClaim }}
            field={field}
            value={value}
            onChange={(urgency: boolean) => onChange(urgency, field.key, group)}
          />
        );
      case 'boolean':
        return (
          <SwitchControl
            disabled={isCurrencyDisabled || !isEditable}
            showLabel={false}
            field={field}
            value={value || false}
            onChange={(checked: boolean) => onChange(checked, field.key, group)}
          />
        );
      case 'select':
        return (
          <DropDownControl
            disabled={!isEditable}
            showLabel={false}
            group={group}
            onChange={onChange}
            field={field}
            value={value}
          />
        );
      case 'address':
        return (
          <AddressBox
            disabled={!isEditable}
            showLabel={false}
            type="text"
            onChange={(address: string) => onChange(address, field.key, group)}
            field={field}
            value={value}
          />
        );
      default:
        // no idea, so let's play it safe;
        console.error(
          `Invalid field control type ${field.control}, please check the type and try again`,
        );
        return false;
    }
  };

  const renderRow = (
    field: IClaimGroupField,
    group: IClaimGroup,
    index: any,
  ): ReactElement | false => {
    // Fetch value from local state
    if (!field.visible || (_.isArray(field.visible) && !_.includes(field.visible, queue))) {
      return false;
    }

    const updatedGroup = updatedClaim[group.key] as any;
    const value = updatedGroup[field.key];
    return (
      <tr key={`${index}`}>
        <td>{String(field.label)}</td>
        <td>{renderInput(field, value, group)}</td>
      </tr>
    );
  };

  const renderClaimGroup = (group: IClaimGroup, claim: IClaim): ReactElement | false => {
    const claimGroup = _.compact(
      _.map(group.fields, (field: IClaimGroupField, index: string) => {
        // Cast as any so we can access prop without implicit any error
        return renderRow(field, group, index);
      }),
    );
    if (_.isEmpty(claimGroup)) {
      return false;
    }
    return (
      <div className="claim-group" key={group.key}>
        <div className="claim-group-label">
          <div>{group.label}</div>
        </div>
        <Table borderless className="sidepanel-info-table">
          <colgroup>
            <col width="30%" />
            <col width="70%" />
          </colgroup>
          <tbody>{_.map(claimGroup)}</tbody>
        </Table>
      </div>
    );
  };
  return (
    <>
      <PerfectScrollbar className={`sidebar-info-scrollbar`}>
        {_.map(groups, (group: IClaimGroup) => renderClaimGroup(group, claim))}
      </PerfectScrollbar>
      {!isDisabled && state.hasChanges ? (
        <div className={`position-fixed-btns edit-buttons ${!state.hasChanges ? 'disabled' : ''}`}>
          <Button color="secondary" outline size="sm" onClick={reset} disabled={!state.hasChanges}>
            Reset
          </Button>
          <Button
            color="secondary"
            size="sm"
            onClick={saveChanges}
            disabled={
              !state.hasChanges || excessCurrency.value !== 'EUR' || jobCurrency.value !== 'EUR'
            }>
            {state.saving ? 'Saving...' : 'Save'}
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default connect(null, { updateClaim })(ClaimInfoEdit);
