/* eslint-disable prettier/prettier */
import { FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import { TextboxProps } from '../../typings';
import React, { ReactElement, useState } from 'react';
import _ from 'lodash';

const Textbox: React.FC<TextboxProps> = ({
  field,
  type,
  placeholder,
  value,
  onChange,
  showLabel = true,
  disabled = false,
  prefix = null,
}) => {
  const [touched, setTouched] = useState(false);
  const renderLabel = (): ReactElement | false => {
    if (showLabel) {
      return (
        <Label for="exampleEmail">
          <div>
            {field.label}
            {field.required ? <span className="text-danger">*</span> : false}
          </div>
          {field.limit ? (
            <div className="field-limit-indicator">
              {_.size(value)}/{field.limit}
            </div>
          ) : (
            false
          )}
        </Label>
      );
    }
    return false;
  };

  const onChangeInput = (event: React.ChangeEvent<any>) => {
    setTouched(true);
    onChange(event);
  };

  const renderInput = () => {
    return (
      <Input
        autoComplete="off"
        rows={5}
        type={type}
        name="text"
        value={value}
        placeholder={placeholder}
        onChange={onChangeInput}
        disabled={disabled}
        maxLength={field.limit}
      />
    );
  };

  const renderWithPrefix = () => {
    return (
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>{prefix}</InputGroupText>
        </InputGroupAddon>
        {renderInput()}
      </InputGroup>
    );
  };

  const isValid = !_.isNull(value) && value !== '';
  const validationClass = isValid ? 'is-valid' : 'invalid';
  const formGroupClass = touched ? validationClass : '';

  return (
    <FormGroup className={formGroupClass}>
      {renderLabel()}
      {prefix ? renderWithPrefix() : renderInput()}
    </FormGroup>
  );
};

export default Textbox;
