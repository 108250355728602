/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import { Button, ModalBody, ModalHeader } from 'reactstrap';
import { DropdownOption, RateModalProps } from '../claims/new/typings';
import { IRatingObject } from '../../../../typings';
import { Rating, RatingView } from 'react-simple-star-rating';
import { connect, useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import Modal from 'react-modal';
import React, { useEffect, useState } from 'react';
import Select, { ValueType } from 'react-select';

const reasons = ['Slow Response.', 'Bad quality service and low customer satisfaction'];

const RateModal: React.FC<RateModalProps> = ({
  isOpen,
  onConfirm,
  onCancel,
  partnerName,
  claimId,
  ratingFlow,
}) => {
  const [rating, setRating] = useState(0);
  const [reason, setReason] = useState('Slow Response.');
  const [allowEdit, setAllowEdit] = useState(true);

  let query: any = [];

  if (claimId) {
    query = [
      {
        collection: 'claims',
        doc: claimId,
        subcollections: [
          {
            collection: 'ratings',
            storeAs: 'ratings',
          },
        ],
        storeAs: 'ratings',
      },
    ];
  }

  useFirestoreConnect(query);

  const ratingObj: IRatingObject = useSelector(({ firestore: { data } }: any) => {
    return data && data.ratings ? data.ratings : null;
  });

  const ratingObjLoaded = useSelector(({ firestore: { data } }: any) => {
    return isLoaded(data.ratings);
  });

  useEffect(() => {
    if (ratingObj && ratingObjLoaded) {
      const ratingsArray = Object.values(ratingObj)[0];
      if (ratingFlow === 'destination' && ratingsArray.destinationPartnerRating.rating > 0) {
        const partnerRating = ratingsArray.destinationPartnerRating.rating.toString();
        setRating(partnerRating);
        setReason(ratingsArray.destinationPartnerRating.reason || 'Slow Response.');
        setAllowEdit(false);
      } else if (ratingFlow === 'origin' && ratingsArray.originPartnerRating.rating > 0) {
        const partnerRating = ratingsArray.originPartnerRating.rating.toString();
        setRating(partnerRating);
        setReason(ratingsArray.originPartnerRating.reason || 'Slow Response.');
        setAllowEdit(false);
      }
    }
  }, [claimId, ratingObj, ratingObjLoaded]);

  const ratingChanged = (newRating: number) => {
    setRating(newRating);
  };

  const options = reasons.map((reason: string) => {
    return {
      value: reason,
      label: reason,
    };
  });

  const confirmRating = () => {
    if (Number(rating) > 0) {
      onConfirm(rating, reason);
    }
  };

  return (
    <div>
      <Modal isOpen={isOpen} className="files-modal" ariaHideApp={false}>
        <ModalHeader>Please rate {partnerName}</ModalHeader>
        <ModalBody>
          {!allowEdit && <h5>You have already rated. Thank you! </h5>}
          <div className="rate-div">
            {allowEdit ? (
              <Rating fillColor="#ffd700" size={60} onClick={ratingChanged} ratingValue={rating} />
            ) : (
              <RatingView fillColor="#ffd700" size={60} ratingValue={rating} />
            )}
            {Number(rating) === 1 && (
              <Select
                className="select-dropdown width-100"
                options={options}
                value={{ label: reason || '', value: reason || '' }}
                onChange={(selectedOption: ValueType<DropdownOption>) => {
                  const selectedValue: string = (selectedOption as unknown as DropdownOption).value;
                  return setReason(selectedValue as string);
                }}
                styles={{
                  menu: (provided, state) => ({
                    ...provided,
                    position: 'unset',
                  }),
                }}
              />
            )}
          </div>
          <div className="cancel-modal-buttons">
            {/* <Button size="sm" color="danger" block onClick={() => onCancel()}>
              Cancel
            </Button> */}
            {allowEdit && (
              <Button size="sm" color="secondary" block outline onClick={confirmRating}>
                Yes, Submit
              </Button>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default connect(null, {})(RateModal);
