/* eslint-disable prettier/prettier */
import { FormGroup, Label } from 'reactstrap';
import { SwitchProps } from '../../typings';
import React from 'react';
import Switch from 'react-switch';

const SwitchControl: React.FC<SwitchProps> = ({
  field,
  value,
  onChange,
  disabled = false,
  showLabel = true,
  formClass = '',
  height = 20,
  width = 50,
}) => {
  return (
    <FormGroup className={formClass}>
      {showLabel ? (
        <Label className="display-block">
          {field.label}
          {field.required ? <span className="text-danger">*</span> : false}
        </Label>
      ) : (
        false
      )}
      <Switch
        onColor="#36c77a"
        offColor="#0f2453"
        onChange={(checked) => onChange(checked)}
        checked={value}
        disabled={disabled}
        className="input-switch"
        height={height}
        width={width}
      />
    </FormGroup>
  );
};

export default SwitchControl;
