/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unescaped-entities */
import * as _ from 'lodash';
import { Badge, Button, Col, Collapse, Row, Table} from 'reactstrap';
import { IClaim, IncomingSidePanelProps } from '../typings';
import { alertTriangle, check, chevronDown, chevronUp, loader, x } from 'react-icons-kit/feather';
import { connect } from 'react-redux';
import Icon from 'react-icons-kit';
import React, { ReactElement, useState } from 'react';

import {
  acceptIncomingClaim,
  cancelClaim,
  rejectIncomingClaim,
  slaDoneMessageCheck,
  uploadFile,
} from '../../../actions/update-claim';

import { toast } from 'react-toastify';
import CancelModal from '../../modals/CancelModal';
import ClaimInfoEdit from '../shared/ClaimInfoEdit';
import IncomingTodo from './todo/IncomingTodo';
import NotesComponent from '../shared/Notes';
import moment from 'moment';

const initialState: { [key: string]: boolean } = {
  accepting: false,
  cancelling: false,
  rejecting: false,
  completing: false,
};

const IncomingSidePanel: React.FC<IncomingSidePanelProps> = ({
  claim,
  todos,
  partner,
  onClose,
  acceptIncomingClaim,
  slaDoneMessageCheck,
  cancelClaim,
}) => {
  const [state, setState] = useState(initialState);
  const [modalOpen, setModalOpen] = useState(false);
  const [reason, setReason] = useState('');
  const [editOpen, setEditOpen] = useState(false);
  const [chatOpen, setChatOpen] = useState(true);
  const [loadingCheck, setLoadingCheck] = useState(false);

  const collapseConfig = {
    show: 100,
    hide: 100,
  };

  const cancel = (e: any) => {
    if (!claim) {
      return false;
    }
    setState({ ...state, cancelling: true });
    cancelClaim(claim.id, reason, 'destination', (err: Error) => {
      setState({ ...state, cancelling: false });
      if (err) {
        console.error(err);
        toast.error('Could not cancel claim, please try again later.');
        return;
      }
      onClose(e);
    });
  };

  const confirmCancel = () => {
    setModalOpen(false);
    cancel(false);
  };

  const rejectCancel = () => {
    setModalOpen(false);
  };

  const showModal = () => {
    setModalOpen(true);
  };

  const acceptClaim = (): void => {
    if (!claim) {
      return;
    }
    setState({ ...state, accepting: true });
    acceptIncomingClaim(claim.id, (err: Error) => {
      setState({ ...state, accepting: false });
      if (err) {
        console.error(err);
        toast.error('Error accepting claim. Please try again later.');
        return;
      }
    });
  };

  const determineCurrentStep = () => {
    if (!todos || !claim) {
      return null;
    }
    let currentTodo = _.first(todos);
    try {
      _.each(todos, (todo, index) => {
        let isNext = {};
        if ( claim.todos['bookInDate'].completed === false ||  claim.todos['confirmEstimate'].completed === false ||  claim.todos['contactClient'].completed === false ||  claim.todos['completeFitment'].completed === false){
          isNext = true;
        } else {
          isNext =
            (claim.todos[todo?.key] ? claim.todos[todo?.key].completed === false : false) ||
            _.isUndefined(claim.todos[todo?.key]);
        }
        if (isNext) {
          const nextIndex = Number(index);
          currentTodo = todos[nextIndex];
          return false;
        }
        return true;
      });
    } catch (e) {
      console.error(e);
    }
    return currentTodo;
  };

  const disableButtons = () => {
    return state.accepting || state.rejecting || state.cancelling;
  };

  const disableCancelButton = (): boolean => {
    const currentStep = determineCurrentStep();
    const inProgress = disableButtons();
    const shouldDisable =
      _.isUndefined(currentStep) || inProgress || currentStep?.allowCancel === false;
    return shouldDisable;
  };

  const onChangeReason = (value: string) => {
    setReason(value);
  };

  const slaDoneCheck = () => {
    if (claim) {
      setLoadingCheck(true);
      slaDoneMessageCheck(claim.id, (err: Error) => {
        setLoadingCheck(false);
        if (err) {
          console.error(err);
          toast.error('Error updating database. Please try again.');
          return;
        }
      });
    }
  };

  const renderClaim = (claim: IClaim): ReactElement | false => {
    const template = partner.claimTemplate.claims;
    if (!claim) {
      return false;
    }

    return (
      <div style={{ height: '100%' }}>
        <div className="header-panel">
          <div>
            <Button className="close-btn" onClick={(e) => onClose(e)}>
              <Icon icon={x} size={28} />
            </Button>
            <h2>
              {claim?.insurer?.claimNumber}
              <Badge className="status-badge">{claim?.status}</Badge>
            </h2>
          </div>
          {!claim?.assign?.owners?.partner ? (
            <div className="info-div">
              <Icon icon={alertTriangle} tag="div" className="info-icon" />
              <div>Please Accept or Reject to continue</div>
            </div>
          ) : null}
          {claim?.status !== 'NEW' &&
          claim?.sla.slaDoneMessage === false &&
          moment(new Date()).isAfter(
            moment(claim._audit.created.timestamp.toDate()).add(72, 'hours'),
          ) ? (
            <div>
              <div className="info-div">
                <Icon icon={alertTriangle} tag="div" className="info-icon" />
                <div className="small-font">
                  It's been 72 hours since the claim has been logged, please drop a note about the
                  progress of the claim
                </div>
              </div>
              {!loadingCheck ? (
                <Icon
                  icon={check}
                  tag="div"
                  size={20}
                  className="info-icon check-icon"
                  onClick={slaDoneCheck}
                />
              ) : (
                <Icon icon={loader} size={20} className="spin ml-10" />
              )}
            </div>
          ) : null}
        </div>
        <Row className="claim">
          <Col sm={6} className="todo-list-container">
            <div className="static">
              <IncomingTodo
                isDisabled={!claim?.assign?.owners?.partner}
                key="incoming-todo"
                claim={claim}
                todos={todos}
              />
            </div>
            <div className="edit-buttons">
              {!disableCancelButton() ? (
                <Button outline size="sm" onClick={showModal} disabled={disableCancelButton()}>
                  {state.cancelling ? 'Rejecting...' : 'Reject Claim'}
                </Button>
              ) : null}
              {!claim?.assign?.owners?.partner ? (
                <Button size="sm" block onClick={acceptClaim} disabled={disableButtons()}>
                  {state.accepting ? 'Accepting...' : 'Accept'}
                </Button>
              ) : null}
            </div>
          </Col>
          <Col sm={6}>
            <div>
              <Button
                className="accordion-btn"
                outline={editOpen ? false : true}
                size="sm"
                onClick={() => {
                  setEditOpen(!editOpen);
                  setChatOpen(!chatOpen);
                }}>
                <div>
                  Claim Info <Icon icon={editOpen ? chevronUp : chevronDown} size={20} />{' '}
                </div>
              </Button>
              <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={editOpen}>
                <ClaimInfoEdit
                  isCurrencyDisabled={true}
                  queue="destination"
                  groups={template}
                  claim={claim}
                  isDisabled={!claim?.assign?.owners?.partner}
                />              
                <div className="scrollbar-container sidebar-info-scrollbar ps">
                  <div className="claim-group">
                    <div className="claim-group-lebal" style={{ fontSize: '1.20em', fontWeight: 'bold', margin: '0px 0px 0px 10px'}}>
                      <div>Claim Originator</div>
                    </div>
                    <Table className="sidepanel-info-table table table-borderless">
                      <colgroup>
                        <col width="30%" />
                        <col width="70%" />
                      </colgroup>
                      <tbody>
                        <tr>
                        <div className="form-group"></div>
                        </tr>
                        <tr>
                          <td>Created By</td>
                          <td>
                            <div className="form-group">
                              <div>
                                {claim?.assign?.origin}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
                &nbsp;
              </Collapse>
            </div>            
            <div>
              <Button
                className="accordion-btn"
                outline={chatOpen ? false : true}
                size="sm"
                onClick={() => {
                  setChatOpen(!chatOpen);
                  setEditOpen(!editOpen);
                }}>
                <div>
                  Claim Notes <Icon icon={chatOpen ? chevronUp : chevronDown} size={20} />{' '}
                </div>
              </Button>
              <Collapse delay={collapseConfig} isOpen={chatOpen}>
                <div className="static">
                  <NotesComponent claim={claim} />
                </div>
              </Collapse>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  if (!claim) {
    return <div></div>;
  }

  return (
    <div>
      <CancelModal
        claim={claim}
        onConfirm={confirmCancel}
        onCancel={rejectCancel}
        isOpen={modalOpen}
        reason={reason}
        onChangeReason={onChangeReason}
      />
      <div className={`side-panel-overlay ${claim ? 'open' : ''}`}></div>
      <div id="incoming-side-panel" className={`side-panel ${claim ? 'open' : ''}`}>
        {claim ? renderClaim(claim) : false}
      </div>
    </div>
  );
};

export default connect(null, {
  acceptIncomingClaim,
  rejectIncomingClaim,
  slaDoneMessageCheck,
  uploadFile,
  cancelClaim,
})(IncomingSidePanel);
