/* eslint-disable prettier/prettier */
import { FormatCurrencyProps } from '../claims/typings';
import React from 'react';
import _ from 'lodash';

const FormatCurrency: React.FC<FormatCurrencyProps> = ({ value, color, addTax, stripCents }) => {
  if (!value) {
    return <span className="currency">-</span>;
  }
  let amount = value;
  if (_.isString(amount)) {
    amount = Number(value);
  }
  const v: number = addTax ? amount * 1.15 : amount;
  let currency = v.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  if (!stripCents && currency.indexOf('.') > -1) {
    currency = currency.split('.').shift() as string;
  }
  return <span className={`currency ${color ? `text-${color}` : ''}`}>{`€${currency}`}</span>;
};

export default FormatCurrency;
