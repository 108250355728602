/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import '../../styles/LoginPage.scss';
import { Button, Form, FormGroup, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { WithFirebaseProps, getFirebase, isEmpty, isLoaded } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { lock, user } from 'react-icons-kit/feather';
import Icon from 'react-icons-kit';
import Loader from 'react-loaders';
import React, { Component } from 'react';
import history from '../../helpers/history';
import login from '../../images/ageLogo.png';

class LoginPage extends Component<
  RouteComponentProps & WithFirebaseProps<any> & LoginPageProps,
  LoginPageState
> {
  private static INITIAL_STATE: LoginPageState = {
    email: '',
    password: '',
    error: null,
    loading: false,
  };

  private static propKey(propertyName: string, value: any): object {
    return { [propertyName]: value };
  }

  private setStateWithEvent(event: any, columnType: string): void {
    this.setState(LoginPage.propKey(columnType, (event.target as any).value));
  }

  constructor(props: any) {
    super(props);

    this.state = { ...LoginPage.INITIAL_STATE };
  }

  componentDidUpdate() {
    if (isLoaded(this.props.auth) && !isEmpty(this.props.auth)) {
      history.push('/');
    }
  }

  public onSubmit = (event: any) => {
    const { email, password } = this.state;
    this.setState(LoginPage.propKey('error', null));
    this.setState(LoginPage.propKey('loading', true));
    this.props.firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        this.setState(LoginPage.propKey('loading', false));
      })
      .catch((err) => {
        this.setState(
          LoginPage.propKey(
            'error',
            'Invalid username and/or password. Please check your username and password and try again.',
          ),
        );
        this.setState(LoginPage.propKey('loading', false));
      });
    event.preventDefault();
  };

  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === '' || email === '';
    return (
      <div className="fill flex center login-page">
        <Helmet>
          <meta charSet="utf-8" />
          <title>AGE | Login</title>
        </Helmet>
        <Form
          className="login-container"
          onSubmit={(event) => this.onSubmit(event)}
        >
          <div className="login-logo">
            <img src={login} alt="logo" />
          </div>
          <div className="form-groups">
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <Icon icon={user} size={18} />
                </InputGroupAddon>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  placeholder="Username/Email"
                  onChange={(event) => this.setStateWithEvent(event, 'email')}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <Icon icon={lock} size={18} />
                </InputGroupAddon>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  placeholder="Password"
                  onChange={(event) => this.setStateWithEvent(event, 'password')}
                />
              </InputGroup>
            </FormGroup>
          </div>
          {error && <p className="text-danger text-center no-margin">{error}</p>}
          {this.state.loading ? (
            <Loader type="ball-pulse-sync" active={true} />
          ) : (
            <Button disabled={isInvalid} type="submit" className="login-button">
              Sign In
            </Button>
          )}
        </Form>
      </div>
    );
  }
}

const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth } }: any) => ({
    firebase: getFirebase(),
    auth,
  }),
);

export default enhance(LoginPage);
