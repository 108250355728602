/* eslint-disable prettier/prettier */
/* eslint-disable sort-imports-es6-autofix/sort-imports-es6 */
/* eslint-disable import/no-duplicates */
/* eslint-disable import/named */
import { FormGroup, Label } from 'reactstrap';
import { IPartner, IPhoneCountry, PhoneInputProps } from '../../typings';
import { connect } from 'react-redux';
import {
  getCountries,
  getCountryCallingCode,
  parsePhoneNumber,
} from 'react-phone-number-input/input';
import { isLoaded } from 'react-redux-firebase';
import { validatePhone } from '../../../../../helpers/utils';
import PhoneInput from 'react-phone-number-input';
import CountrySelectComponentProps from 'react-phone-number-input';
import React, { ReactElement, useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import Select, { OptionsType } from 'react-select';
import _ from 'lodash';
import en from 'react-phone-number-input/locale/en.json';

const countryCodes = en as any;

let options = _.compact(
  _.map(getCountries(), (country) => {
    if (!country) {
      return false;
    }
    return {
      value: country,
      code: getCountryCallingCode(country),
      codeFull: `+${getCountryCallingCode(country)}`,
      country: countryCodes[country],
    };
  }),
);

const PhoneNumber: React.FC<PhoneInputProps> = ({
  field,
  claim,
  countries,
  value,
  onChange,
  showLabel = true,
  disabled = false,
  prefix = null,
}) => {
  const [country, setCountry] = useState();
  const [touched, setTouched] = useState(false);
  const [claimNumber, setClaimNumber] = useState('');

  useEffect(() => {
    parseCountryCode(value);
  }, [value]);

  useEffect(() => {
    if (claimNumber !== claim?.insurer?.claimNumber) {
      setCountry(undefined);
      setClaimNumber(claim?.insurer?.claimNumber || '');
      setTimeout(() => parseCountryCode(value), 0);
    }
  }, [claim]);

  const updateCountry = (
    value: { [key: string]: string } | OptionsType<{ [key: string]: string }> | undefined,
    onChangeMethod: any,
  ) => {
    const c = value as any;
    const countryCode = c ? (c.value as string) : undefined;
    onChangeMethod(countryCode);
    setCountry(value);
  };

  const renderLabel = (): ReactElement | false => {
    if (showLabel) {
      return (
        <Label for="phone">
          <div>
            {field.label}
            {field.required ? <span className="text-danger">*</span> : false}
          </div>
        </Label>
      );
    }
    return false;
  };

  const renderOption = (option: { [key: string]: string }) => {
    const { value, codeFull } = option;
    return (
      <div>
        <ReactCountryFlag svg countryCode={value} /> {codeFull}
      </div>
    );
  };

  const checkMatch = (candidate: string, value: string) => {
    return candidate.includes(value);
  };

  const searchCountryCode = (candidate: any, input: string) => {
    const {
      data: { value, code, codeFull },
    } = candidate;
    if (input) {
      return checkMatch(value, input) || checkMatch(code, input) || checkMatch(codeFull, input);
    }
    return true;
  };

  options = countries || options;

  const countrySelect = (props: typeof CountrySelectComponentProps, defaultCountry: string) => {
    let countryValue = { value: defaultCountry, codeFull: '+34' };
    if (country) {
      countryValue = country;
    }
    return (
      <Select
        className="phone-country-dropdown"
        options={options}
        value={countryValue}
        isDisabled={disabled}
        isSearchable={false}
        filterOption={searchCountryCode}
        formatOptionLabel={renderOption}
        onChange={(value) => updateCountry(value || undefined, props.onChange)}
      />
    );
  };

  const parseCountryCode = (phone: string) => {
    if (country) {
      return false;
    }

    const phoneNumber = parsePhoneNumber(String(phone));
    if (phoneNumber && phoneNumber.country) {
      const payload = {
        value: phoneNumber.country,
        code: phoneNumber.countryCallingCode,
        codeFull: `+${phoneNumber.countryCallingCode}`,
        country: countryCodes[phoneNumber.country],
      };
      setCountry(payload);
    }
  };

  const onChangePhoneNumber = (phone: any) => {
    if (!phone) {
      return false;
    }
    setTouched(true);
    onChange(phone);
  };
  const defaultCountry = 'ES';

  const renderInput = () => {
    return (
      <PhoneInput
        countrySelectComponent={(props) => countrySelect(props, defaultCountry)}
        defaultCountry={defaultCountry}
        name={field.key}
        value={value}
        onChange={onChangePhoneNumber}
        disabled={disabled}
        numberInputProps={{ className: 'form-control' }}
      />
    );
  };
  const isValid = !_.isEmpty(value) && validatePhone(value);
  const validationClass = isValid ? 'is-valid' : 'invalid';
  const formGroupClass = touched ? validationClass : '';

  return (
    <FormGroup className={`relative ${formGroupClass}`}>
      {renderLabel()}
      {renderInput()}
    </FormGroup>
  );
};

const mapStateToProps = ({ firestore }: any) => {
  const partners = firestore.data.ref_partners;
  const ready = isLoaded(partners);
  const customCountries: IPhoneCountry[] = [];
  if (ready) {
    _.each(partners, (partner: IPartner) => {
      const countryCode = _.findKey(countryCodes, (country: string) => partner.country === country);
      if (countryCode) {
        const payload: IPhoneCountry = {
          value: countryCode,
          code: getCountryCallingCode(countryCode),
          codeFull: `+${getCountryCallingCode(countryCode)}`,
          country: partner.country,
        };
        customCountries.push(payload);
      }
    });
  }
  return { countries: customCountries };
};

export default connect(mapStateToProps)(PhoneNumber);
