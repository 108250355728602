/* eslint-disable prettier/prettier */
import { Badge, Button, Col, Form, Label, TabPane } from 'reactstrap';
import { ClaimFormSectionProps, DropdownOption, IClaimGroup, IClaimGroupField } from '../typings';
import { FileDropZone } from '../../side-panel/inputs/FileDropzone';
import { INewClaimPayload } from '../../../../../../typings';
import { Icon } from 'react-icons-kit';
import { connect } from 'react-redux';
import { currencyConverter } from '../../../../helpers/utils';
import {
  initializeNewClaimValue,
  resetClaim,
  submitNewClaim,
  updateCurrencyChangeObj,
  updateCurrencyValues,
  updateNewClaimValue,
} from '../../../../actions/new-claim';
import { removeFiles } from '../../../../actions/files';
import { toast } from 'react-toastify';
import { trash2 } from 'react-icons-kit/feather';
import { uploadFile } from '../../../../actions/update-claim';
import AddressBox from './inputs/Address';
import ConfirmModal from '../../../modals/ConfirmModal';
import CurrencyInput from './inputs/CurrencyInput';
import DatePicker from './inputs/DatePicker';
import DropDownControl from './inputs/Dropdown';
import EmailInput from './inputs/EmailInput';
import PhoneNumber from './inputs/PhoneNumber';
import React, { ReactElement, useState } from 'react';
import SwitchControl from './inputs/Switch';
import Textbox from './inputs/Textbox';
import Urgency from './inputs/Urgency';
import YearInput from './inputs/YearInput';
import _ from 'lodash';
import history from '../../../../helpers/history';
import moment from 'moment';

const initialState: { loading: boolean } = {
  loading: false,
};

const defaultCurrency = { label: 'EUR', value: 'EUR' };

const currencyChangeObj = {
  authorizedValue: {
    key: 'authorizedValue',
    value: 0,
    conversionRate: 0,
    valueInEUR: 0,
    currency: '',
  },
  excessValue: {
    key: 'excessValue',
    value: 0,
    conversionRate: 0,
    valueInEUR: 0,
    currency: '',
  },
  jobCostEstimate: {
    key: 'jobCostEstimate',
    value: 0,
    conversionRate: 0,
    valueInEUR: 0,
    currency: '',
  },
};

const ClaimFormSection: React.FC<ClaimFormSectionProps> = ({
  id,
  group,
  newClaim,
  next,
  prev,
  updateNewClaimValue,
  initializeNewClaimValue,
  submitNewClaim,
  resetClaim,
  updateCurrencyValues,
  updateCurrencyChangeObj,
}) => {
  const [state, setState] = useState(initialState);
  const [modalOpen, setModalOpen] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState([]);

  const setCurrencyValue = (key: string, value: DropdownOption) => {
    updateCurrencyValues(key, value);
  };

  const submitClaim = () => {
    const claim = newClaim.claim;
    setState({ loading: true });
    submitNewClaim(claim, newClaim.currencyChangeObj, (err: Error) => {
      setState({ loading: false });
      resetClaim();
      if (err) {
        console.error(err);
        toast.error('Could not create claim. Please try again later');
        return false;
      }
      history.push('/');
      return true;
    });
  };

  const confirmSubmit = () => {
    setModalOpen(false);
    submitClaim();
  };

  const cancelSubmit = () => {
    setModalOpen(false);
  };

  const showModal = async () => {
    const currencyKeys = Object.keys(newClaim.currencyObj);
    const currencyValues = Object.values(newClaim.currencyObj);
    await Promise.all(
      _.map(currencyValues, async (el: any, index: number) => {
        if (el.value !== 'EUR') {
          const value = newClaim.currencyObj[currencyKeys[index]].value;
          const conversionRate = await currencyConverter(value);
          let costInEUR = 0;
          if (currencyKeys[index] === 'authorizedValue') {
            costInEUR = conversionRate * (newClaim.claim.insurer.authorizedValue || 0);
            costInEUR = Number(costInEUR.toFixed(2));
            currencyChangeObj['authorizedValue'] = {
              key: 'authorizedValue',
              value: Number(newClaim.claim.insurer.authorizedValue?.toFixed(2)) || 0,
              conversionRate: conversionRate,
              valueInEUR: costInEUR,
              currency: value,
            };
            newClaim.claim.insurer.authorizedValue = costInEUR;
          } else if (currencyKeys[index] === 'excessValue') {
            costInEUR = conversionRate * (newClaim.claim.insurer.excessValue || 0);
            costInEUR = Number(costInEUR.toFixed(2));
            currencyChangeObj['excessValue'] = {
              key: 'excessValue',
              value: Number(newClaim.claim.insurer.excessValue?.toFixed(2)) || 0,
              conversionRate: conversionRate,
              valueInEUR: costInEUR,
              currency: value,
            };
            newClaim.claim.insurer.excessValue = costInEUR;
          } else if (currencyKeys[index] === 'jobCostEstimate') {
            costInEUR = conversionRate * (newClaim.claim.assign.jobCostEstimate || 0);
            costInEUR = Number(costInEUR.toFixed(2));
            currencyChangeObj['jobCostEstimate'] = {
              key: 'jobCostEstimate',
              value: Number(newClaim.claim.assign.jobCostEstimate?.toFixed(2)) || 0,
              conversionRate: conversionRate,
              valueInEUR: costInEUR,
              currency: value,
            };
            newClaim.claim.assign.jobCostEstimate = costInEUR;
          }
        }
      }),
    );
    updateCurrencyValues('authorizedValue', defaultCurrency);
    updateCurrencyValues('excessValue', defaultCurrency);
    updateCurrencyValues('jobCostEstimate', defaultCurrency);
    newClaim.currencyChangeObj = currencyChangeObj;
    updateCurrencyChangeObj(currencyChangeObj);
    setModalOpen(true);
  };

  const getValue = (
    value:
      | React.ChangeEvent<HTMLInputElement>
      | moment.Moment
      | string
      | boolean
      | undefined
      | null,
    key: string,
    group: IClaimGroup,
    isNumber = false,
  ): any => {
    if (_.isUndefined(value)) {
      console.error(`No value to change for ${String(group.key)}|${key}`);
      return;
    }
    let payload: string | boolean | number | null | Date = moment.isMoment(value)
      ? value.toDate()
      : _.isBoolean(value)
      ? value
      : _.isString(value)
      ? value
      : value
      ? value.target.value
      : null;
    if (isNumber && _.isString(payload) && _.last(payload) !== '.') {
      payload = Number(payload);
      if (_.isNaN(payload)) {
        console.error(`Invalid value type for  ${String(group.key)}|${key}`);
      }
    }
    return payload;
  };

  const initialize = (
    value:
      | React.ChangeEvent<HTMLInputElement>
      | moment.Moment
      | string
      | boolean
      | undefined
      | null,
    key: string,
    group: IClaimGroup,
    isNumber = false,
  ) => {
    const payload = getValue(value, key, group, isNumber);
    initializeNewClaimValue(group.key, key, payload, group);
  };

  const currencyChangeObjFn = (obj: {
    key: 'authorizedValue' | 'excessValue' | 'jobCostEstimate';
    valueInEUR: number;
    conversionRate: number;
    currency: string;
    value: number;
  }) => {
    currencyChangeObj[obj.key] = {
      key: obj.key,
      value: Number(+obj.value.toFixed(2)),
      valueInEUR: Number(+obj.valueInEUR.toFixed(2)),
      conversionRate: obj.conversionRate,
      currency: obj.currency,
    };
  };

  const onChange = (
    value:
      | React.ChangeEvent<HTMLInputElement>
      | moment.Moment
      | string
      | boolean
      | undefined
      | null,
    key: string,
    group: IClaimGroup,
    isNumber = false,
  ): void => {
    const payload = getValue(value, key, group, isNumber);
    updateNewClaimValue(group.key, key, payload, group);
  };

  const renderInput = (field: IClaimGroupField, value: any): ReactElement | false => {
    switch (field.control) {
      case 'email':
        return (
          <EmailInput
            type="email"
            value={value || ''}
            field={field}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, field.key, group)}
          />
        );
      case 'string':
        // Text field
        return (
          <Textbox
            type="text"
            value={value || ''}
            field={field}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, field.key, group)}
          />
        );
      case 'textarea':
        return (
          <Textbox
            type="textarea"
            value={value || ''}
            field={field}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, field.key, group)}
          />
        );
      case 'phone':
        return (
          <PhoneNumber
            type="text"
            value={value}
            field={field}
            onChange={(phone) => onChange(phone, field.key, group)}
          />
        );
      case 'number':
        return (
          <Textbox
            type="text"
            value={value}
            field={field}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e, field.key, group, true)
            }
          />
        );
      case 'currency':
        return (
          <CurrencyInput
            setCurrency={(value) => {
              setCurrencyValue(field.key, value);
            }}
            currency={newClaim.currencyObj[field.key]}
            type="number"
            value={value}
            field={field}
            onChange={(
              e:
                | React.ChangeEvent<HTMLInputElement>
                | moment.Moment
                | string
                | boolean
                | undefined
                | null,
              key: string,
              isNumber = false,
            ) => onChange(e, field.key, group, true)}
            currencyChangeObjFn={currencyChangeObjFn}
          />
        );
      case 'year':
        return (
          <YearInput
            type="number"
            value={value}
            field={field}
            onChange={(year: string) => onChange(year, field.key, group, true)}
          />
        );
      case 'Timestamp':
        return (
          <DatePicker
            field={field}
            value={value || moment()}
            onChange={(date: moment.Moment | null) => onChange(date, field.key, group)}
            withPortal={false}
          />
        );
      case 'display':
        return (
          <Urgency
            claim={newClaim}
            field={field}
            value={value}
            onChange={(urgency: boolean) => initialize(urgency, field.key, group)}
          />
        );
      case 'boolean':
        return (
          <SwitchControl
            field={field}
            value={value || false}
            height={28}
            width={56}
            onChange={(checked: boolean) => onChange(checked, field.key, group)}
          />
        );
      case 'select':
        return (
          <DropDownControl
            group={group}
            onChange={onChange}
            field={field}
            value={value}
            disabled={false}
          />
        );
      case 'address':
        return (
          <AddressBox
            type="text"
            onChange={(address: string) => onChange(address, field.key, group)}
            field={field}
            value={value}
          />
        );
      default:
        // no idea, so let's play it safe;
        console.error(
          `Invalid field control type ${field.control}, please check the type and try again`,
        );
        return false;
    }
  };

  const validateClaim = (): boolean => {
    const isValid = !state.loading && _.isEmpty(newClaim.empty) && _.isEmpty(newClaim.invalid);
    return !isValid;
  };

  const onDropFile = (files: any): void => {
    setAttachedFiles(files);
    updateNewClaimValue('assign', 'attachments', files, group);
  };

  const renderSectionWarnings = (): ReactElement | false => {
    if (next) {
      return (
        <div className="section-warnings">
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
      );
    }
    const invalid = newClaim.invalid ? _.map(newClaim.invalid, (i, k) => k) : [];
    const empty = newClaim.empty ? newClaim.empty : [];
    let warnings = _.uniq([...invalid, ...empty]);
    warnings = _.map(warnings, (warning) => {
      return warning.replace(/([a-z])([A-Z])/g, '$1 $2');
    });
    if (_.isEmpty(warnings)) {
      return false;
    }
    return (
      <div className="section-warnings">
        <p className="text-danger">
          Please complete the following sections before submitting your claim:
        </p>
        <p>
          {_.map(warnings, (warning, index) => (
            <Badge key={index} className="section-warning-badge capitalize">
              {warning}
            </Badge>
          ))}
        </p>
      </div>
    );
  };

  const showLoaderTrash = (): ReactElement | false => {
    return <Icon icon={trash2} size={16} />;
  };

  const deleteFiles = () => {
    setModalOpen(false);
    setAttachedFiles([]);
    updateNewClaimValue('assign', 'attachments', [], group);
  };

  return (
    <TabPane tabId={String(id)}>
      <Form row="true" className="claim-form-body row" autoComplete="off">
        {_.map(group.fields, (field) => {
          // Get the value from the newClaim Reducer state that was passed down
          const claim: INewClaimPayload = newClaim.claim;
          const claimGroup: any = claim[group.key];
          const value = claimGroup[field.key];
          const input = renderInput(field, value);
          if (!input) {
            return false;
          }
          return (
            <Col key={`field-${field.key}`} md={6} xs={12}>
              {input}
            </Col>
          );
        })}
        {String(id) === '4' && (
          <Col md={6} xs={12}>
            <Label>Attachments</Label>
            {!attachedFiles.length ? (
              <FileDropZone
                loading={false}
                onError={toast.error}
                hideText={false}
                onDrop={onDropFile}
                className="todo-drop-file"
              />
            ) : (
              <div className="files-attached-div">
                <span className="text">{attachedFiles.length} Files Attached</span>
                <div className="icons-div">
                  <Button
                    className="file-single trash"
                    color="link"
                    onClick={deleteFiles}
                    size="sm">
                    {showLoaderTrash()}
                  </Button>
                </div>
              </div>
            )}
          </Col>
        )}
      </Form>
      <div className="claim-form-buttons">
        {!next ? (
          <ConfirmModal
            claim={newClaim.claim}
            onConfirm={confirmSubmit}
            onCancel={cancelSubmit}
            isOpen={modalOpen}
          />
        ) : (
          false
        )}
        <div className="claim-buttons">
          {renderSectionWarnings()}
          <div>
            <Button
              color="secondary"
              outline
              disabled={!prev}
              onClick={() => (_.isFunction(prev) ? prev() : _.noop())}>
              Prev
            </Button>
            {!next ? (
              <Button color="secondary" onClick={showModal} disabled={validateClaim()}>
                {state.loading ? 'Creating Claim...' : 'Create Claim'}
              </Button>
            ) : (
              <Button
                color="secondary"
                size="xl"
                disabled={!next}
                onClick={() => (_.isFunction(next) ? next() : _.noop())}>
                Next
              </Button>
            )}
          </div>
        </div>
      </div>
    </TabPane>
  );
};

export default connect(null, {
  updateNewClaimValue,
  initializeNewClaimValue,
  submitNewClaim,
  resetClaim,
  uploadFile,
  removeFiles,
  updateCurrencyValues,
  updateCurrencyChangeObj,
})(ClaimFormSection);
