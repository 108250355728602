/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import { ActionCreator } from 'redux';
import {
  CURRENCY_CHANGE_OBJ,
  CURRENCY_UPDATE_VALUE,
  NC_INIT_VALUE,
  NC_RESET_CLAIM,
  NC_UPDATE_VALUE,
} from './types';
import { IClaimGroup } from '../../components/claims/new/typings';
import { INewClaimPayload, INewNewsFeedPayload } from '../../../../typings';
import { convertFirebaseDates } from './helpers/DateHelper';
import { functions } from '../../config/firebase';

export const updateNewClaimValue = (
  group: string,
  key: string,
  value: any,
  validation: IClaimGroup,
) => {
  return (dispatch: any) => {
    dispatch({
      type: NC_UPDATE_VALUE,
      payload: { group, key, value, validation },
    });
  };
};

export const updateCurrencyValues = (key: string, value: any) => {
  return (dispatch: any) => {
    dispatch({
      type: CURRENCY_UPDATE_VALUE,
      payload: { key, value },
    });
  };
};

export const updateCurrencyChangeObj = (currencyChangeObj: any) => {
  return (dispatch: any) => {
    dispatch({
      type: CURRENCY_CHANGE_OBJ,
      payload: currencyChangeObj,
    });
  };
};

export const initializeNewClaimValue = (
  group: string,
  key: string,
  value: any,
  validation: IClaimGroup,
) => {
  return (dispatch: any) => {
    dispatch({
      type: NC_INIT_VALUE,
      payload: { group, key, value, validation },
    });
  };
};

export const resetClaim = (empty: string[]) => {
  return (dispatch: any) => {
    dispatch({ type: NC_RESET_CLAIM });
  };
};

export const submitNewClaim = (
  claim: INewClaimPayload,
  currencyChangeObj: any,
  callback: Function,
) => {
  return (dispatch: any) => {
    const payload = convertFirebaseDates(claim);
    const createNewClaim = functions.httpsCallable('createNewClaim');
    createNewClaim({ ...payload, currencyChangeObj })
      .then(() => {
        callback();
      })
      .catch((err) => {
        console.error(err);
        console.error('FAILED');
        callback(err);
      });
  };
};

export const submitNewNewsFeed = (
  claim: INewNewsFeedPayload,
  callback: Function,
) => {
  return (dispatch: any) => {
    const payload = convertFirebaseDates(claim);
    const createNewNewsFeed = functions.httpsCallable('createNewNewsFeed');
    createNewNewsFeed({ ...payload })
      .then(() => {
        callback();
      })
      .catch((err) => {
        console.error(err);
        console.error('FAILED');
        callback(err);
      });
  };
};

export const getNewsFeed: ActionCreator<any> = async () => {
  try {
    const getNewsFeed = functions.httpsCallable('getNewsFeed');
    const newsFeedData = await getNewsFeed();
    // const feedData = [];
    // feedData.push(newsFeedData);
    //return newsFeedData; //Object.values(feedData);
    return {
      type: 'GET_ALL_NEWSFEED',
      newsFeedData,
  }
  } catch (e) {
    console.error(e);
  }
};