/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import '../../styles/NewClaimPage.scss';
import {
  FirebaseReducer,
  FirestoreReducer,
  ReduxFirestoreQueries,
  firestoreConnect,
  isLoaded,
} from 'react-redux-firebase';
import { INewClaimReducer } from '../../reducers/reducers/typings';
// import { NewsComponentProps } from './typings';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { resetClaim } from '../../actions/new-claim';
import Helmet from 'react-helmet';
import LoadingPage from '../loading/LoadingPage';
import NewsForm from '../newsFeed';
import React, { Component } from 'react';
import _ from 'lodash';

class SendNotificationPage extends Component<
  RouteComponentProps & FirebaseReducer.Reducer> {

//   componentDidMount() {
//     this.props.resetClaim();
//   }

  render() {
    // const { claimConfig, newClaim } = this.props;
    return (
      <div className="padded-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>AGE | Send News</title>
        </Helmet>
       <NewsForm/> 
       
      </div>
    );
  }
}

const preMapStateToProps = (state: any) => {
  const {
    firebase: { profile },
  }: { firebase: FirebaseReducer.Reducer } = state;
  return {
    profile: profile,
  };
};

const mapQueryToProps = ({ profile }: { profile: any }): ReduxFirestoreQueries => {
  if (!profile.isLoaded || profile.isEmpty) {
    return [];
  }
  return [
    {
      collection: 'config',
      doc: 'claims',
      storeAs: 'claimsConfig',
    },
    {
      collection: 'partners',
      doc: profile.partnerId,
      storeAs: 'partner',
    },
  ];
};

const mapStateToProps = (state: any) => {
  const {
    firestore,
    firebase: { auth },
    newClaim,
  }: {
    firestore: FirestoreReducer.Reducer;
    firebase: FirebaseReducer.Reducer;
    newClaim: INewClaimReducer;
  } = state;
  const claimsConfig = firestore.data.claimsConfig;
  const partner = firestore.data.partner;
  const loading = !isLoaded(claimsConfig) || !isLoaded(partner);
  let claims = null;
  if (!loading) {
    const masterClaim = claimsConfig.claims;
    const partnerConfig = partner.claimTemplate.claims;
    claims = _.assign({}, masterClaim, partnerConfig);
  }
  return {
    claimConfig: {
      claims: claims,
      loading: loading,
    },
    newClaim,
    auth: auth as FirebaseReducer.AuthState,
  };
};

export default compose(
  connect(preMapStateToProps),
  firestoreConnect(mapQueryToProps),
  connect(mapStateToProps, { resetClaim }),
)(SendNotificationPage);