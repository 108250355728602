/* eslint-disable prettier/prettier */
import { CheckboxProps } from '../../new/typings';
import { FormGroup, Label } from 'reactstrap';
import React from 'react';
import SimpleCheckbox from 'react-simple-checkbox';

const Checkbox: React.FC<CheckboxProps> = ({ label, size, value, disabled = false, onChange }) => {
  return (
    <FormGroup className="checkbox-container">
      <SimpleCheckbox checked={value} size={size || 3} disabled={disabled} onChange={onChange} />
      <Label>{label}</Label>
    </FormGroup>
  );
};

export default Checkbox;
