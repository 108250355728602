/* eslint-disable prettier/prettier */
import moment from 'moment';

export interface ISearchClaimReducerState {
  toDate: string;
  fromDate: string;
  search: boolean;
}

const INIT: ISearchClaimReducerState = {
  toDate: moment('2100-01-01').toDate().toString(),
  fromDate: moment('2000-01-01').toDate().toString(),
  search: false,
};

export default (state = INIT, action: any) => {
  switch (action.type) {
    case 'SET_TO_DATE_VALUE':
      return {
        ...state,
        toDate: action.value,
      };
    case 'SET_FROM_DATE_VALUE':
      return {
        ...state,
        fromDate: action.value,
      };
    case 'SEARCH_DATE_RANGE':
      return {
        ...state,
        search: action.value,
      };
    default:
      return { ...state };
  }
};
