/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import { DropdownOption, DropdownProps, DropdownState, IClaimGroupField } from '../../typings';
import {
  FirebaseReducer,
  FirestoreReducer,
  ReduxFirestoreQueries,
  firestoreConnect,
  isLoaded,
} from 'react-redux-firebase';
import { FormGroup, Label } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import _ from 'lodash';

import Loader from 'react-loaders';
import Select, { ValueType } from 'react-select';

const processSelectOptions = (options: string[]): DropdownOption[] => {
  return _.map(options, (o) => {
    return {
      value: o,
      label: o,
    };
  });
};

const processDataOptions = (
  options: any[],
  valueKey: string,
  labelKey: string,
): DropdownOption[] => {
  return _.map(options, (o) => {
    return {
      value: o[valueKey],
      label: `${o.country} - ${o[labelKey]}`,
    };
  });
};

class DropDownComponent extends Component<FirebaseReducer.Reducer & DropdownProps, DropdownState> {
  constructor(props: any) {
    super(props);
    this.state = {
      touched: false,
    };
    this.setTouched = this.setTouched.bind(this);
  }

  setTouched(value: boolean) {
    this.setState({ touched: value });
  }

  render() {
    const {
      field,
      group,
      value,
      data,
      dataIndexed,
      loading,
      showLabel = true,
      disabled = false,
    } = this.props;
    const { touched } = this.state;
    if (field.ref && loading) {
      return <Loader type="ball-pulse-sync" active={true} />;
    }
    let options: DropdownOption[] = processSelectOptions(field.options as string[]);
    if (field.ref) {
      options = processDataOptions(data, 'id', 'name');
    }

    let selected: ValueType<DropdownOption> = { value: value, label: value };
    if (field.ref && value && dataIndexed[value]) {
      selected = {
        value: value,
        label: `${dataIndexed[value].country} - ${dataIndexed[value].name}`,
      };
    }

    const isValid = touched === true;
    const validationClass = isValid ? 'is-valid' : 'invalid';
    const formGroupClass = touched ? validationClass : '';
    return (
      <FormGroup className={formGroupClass}>
        {showLabel ? (
          <Label className="display-block">
            {field.label}
            {field.required ? <span className="text-danger">*</span> : false}
          </Label>
        ) : (
          false
        )}

        <Select
          className={`${disabled ? 'select-dropdown-disabled' : 'select-dropdown'}`}
          options={options}
          value={selected}
          isDisabled={disabled}
          onChange={(selectedOption: ValueType<DropdownOption>) => {
            this.setTouched(true);
            const selectedValue: string = (selectedOption as unknown as DropdownOption).value;
            return this.props.onChange(selectedValue as string, field.key, group);
          }}
        />
      </FormGroup>
    );
  }
}

const preMapStateToProps = (state: any, ownProps: any) => {
  const {
    firebase: { profile },
  }: { firebase: FirebaseReducer.Reducer } = state;
  const { field }: { field: IClaimGroupField } = ownProps;
  return {
    profile: profile,
    fieldRef: field.ref || null,
  };
};

const mapQueryToProps = (ownProps: {
  profile: any;
  fieldRef: string;
  ready: boolean;
}): ReduxFirestoreQueries => {
  const { profile, fieldRef } = ownProps;
  if (!profile.isLoaded || profile.isEmpty || !fieldRef) {
    return [];
  }
  return [
    {
      collection: fieldRef,
      storeAs: `ref_${fieldRef}`,
    },
  ];
};

const mapStateToProps = (state: any, ownProps: any) => {
  const {
    firestore,
    firebase: { auth },
  }: {
    firestore: FirestoreReducer.Reducer;
    firebase: FirebaseReducer.Reducer;
  } = state;
  const { fieldRef } = ownProps;
  const partner = firestore.data.partner;

  let fieldData = null;
  let fieldDataById = null;
  let loading = !isLoaded(partner);
  if (fieldRef) {
    fieldData = firestore.ordered[`ref_${fieldRef}`];
    fieldDataById = firestore.data[`ref_${fieldRef}`];
    loading = !isLoaded(fieldData);
  }

  return {
    loading,
    partner,
    data: fieldData?.filter((x) => x.active == true),
    dataIndexed: fieldDataById,
    auth: auth as FirebaseReducer.AuthState,
  };
};

export default compose<React.ComponentType<DropdownProps>>(
  connect(preMapStateToProps),
  firestoreConnect(mapQueryToProps),
  connect(mapStateToProps),
)(DropDownComponent);
