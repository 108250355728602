/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import { AddressProps } from '../../typings';
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputProps,
  Label,
} from 'reactstrap';
import { Icon } from 'react-icons-kit';
import { mapPin } from 'react-icons-kit/feather';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import React, { ReactElement, useEffect, useState } from 'react';
import _ from 'lodash';

const AddressBox: React.FC<AddressProps> = ({
  field,
  type,
  placeholder,
  value,
  onChange,
  showLabel = true,
  disabled = false,
  prefix = null,
}) => {
  const [address, setAddress] = useState('');
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    // code to run on component mount
    setAddress(value);
  }, [value]);

  const renderLabel = (): ReactElement | false => {
    if (showLabel) {
      return (
        <Label for="exampleEmail">
          <div>
            {field.label}
            {field.required ? <span className="text-danger">*</span> : false}
          </div>
        </Label>
      );
    }
    return false;
  };

  const updateAddress = (event: React.ChangeEvent<HTMLInputElement>, props: any) => {
    setTouched(true);
    setAddress(event.target.value);
    props.onChange(event);
    onChange(event.target.value);
  };

  const renderInput = (props: InputProps) => {
    return (
      <InputGroup className={`${disabled ? 'disabled' : ''}`}>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <Icon icon={mapPin} />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          {...props}
          placeholder={placeholder}
          value={address}
          onChange={(e) => updateAddress(e, props)}
          disabled={disabled}
          id={`google-places-${field.key}`}
        />
      </InputGroup>
    );
  };

  const onSelect = (address: any, other: any) => {
    setTouched(false);
    const { description } = address;
    setAddress(description);
    onChange(description);
  };

  const isValid = !_.isEmpty(value);
  const validationClass = isValid ? 'is-valid' : 'invalid';
  const formGroupClass = touched ? validationClass : '';

  return (
    <FormGroup className={formGroupClass}>
      {renderLabel()}
      <GooglePlacesAutocomplete
        onSelect={onSelect}
        inputClassName="form-control"
        renderInput={renderInput}
        debounce={1000}
      />
    </FormGroup>
  );
};

export default AddressBox;
