/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Button } from 'reactstrap';
import { IClaim, OutgoingTodoProps } from '../../typings';
import { IClaimGroupField, IStringKeyPair } from '../../new/typings';
import { IClaimTodo, ITodo } from '../../../../../../typings';
import { checkSquare, file as fileIcon } from 'react-icons-kit/feather';
import { connect } from 'react-redux';
import { dotNotationReference } from '../../../../helpers/utils';
import { toast } from 'react-toastify';
import { uploadFile } from '../../../../actions/update-claim';
import Checkbox from '../inputs/Checkbox';
import DatePicker from '../../new/ClaimForm/inputs/DatePicker';
import FileSingle from '../files/FileSingle';
import FilesViewModal from '../../../modals/FilesViewModal';
import FormatCurrency from '../../../shared/FormatCurrency';
import Icon from 'react-icons-kit';
import React, { ReactElement, useEffect, useState } from 'react';
import SwitchControl from '../../new/ClaimForm/inputs/Switch';
import Textbox from '../../new/ClaimForm/inputs/Textbox';
import TodoFile from '../inputs/TodoFile';
import _ from 'lodash';
import moment from 'moment';

const initialState: {
  fields: IStringKeyPair;
  todos: { [key: string]: IClaimTodo };
  reason: { [key: string]: string };
} = {
  fields: {},
  todos: {},
  reason: {},
};

const OutgoingTodoList: React.FC<OutgoingTodoProps> = ({ claim, todos, uploadFile }) => {
  const [state, setState] = useState(initialState);
  const [fileLoading, setFileLoading] = useState(false);
  const [fileModalOpen, setFileModalOpen] = useState(false);

  // Update the state with claim data for steps
  // claim.todos[todo.key].completed
  useEffect(() => {
    if (!claim) {
      return;
    }
    const fields: any = {};
    const stateTodos: any = {};
    const stateReasons: any = {};

    _.each(todos, (todo: ITodo) => {
      stateTodos[todo.key] = {
        completed: claim.todos[todo.key] ? claim.todos[todo.key].completed : false,
      };
      const reason = claim.todos[todo.key]?.reason ? claim.todos[todo.key].reason : null;
      stateReasons[todo.key] = reason;
      fields[todo.key] = claim.metadata ? claim.metadata[todo.key] : false;
      if (todo.fields && todo.fields[todo.key] && todo.fields[todo.key].ref) {
        const ref = todo.fields[todo.key].ref;
        if (ref) {
          fields[todo.key] = dotNotationReference(ref, claim);
        }
      }
    });
    setState({
      ...state,
      todos: stateTodos,
      fields: fields,
      reason: stateReasons,
    });
    // we don't need exhaustive hook dep check, shut the linter up.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todos]);

  const onChangeTodoInput = (fieldKey: string, value: any): void => {
    setState({
      ...state,
      fields: {
        ...state.fields,
        [fieldKey]: value,
      },
    });
  };

  const onDropFile = (todo: ITodo, field: IClaimGroupField, files: any): void => {
    if (!claim) {
      return;
    }
    setFileLoading(true);
    const id = claim.id as string;
    uploadFile(id, { files, key: todo.key }, (err: Error) => {
      setFileLoading(false);
      if (err) {
        console.error(err);
        return toast.error('Error uploading invoice, please try again later');
      }
      const successMessage = (
        <div className="toast-message">
          <Icon icon={checkSquare} size={36} /> Successfully uploaded file.
        </div>
      );
      return toast.success(successMessage, {
        autoClose: 3000,
        closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
      });
    });
  };

  const renderInput = (
    todo: ITodo,
    fieldKey: string,
    field: IClaimGroupField,
  ): ReactElement | false => {
    const value = state.fields[fieldKey];
    switch (field.control) {
      case 'string':
        // Text field
        return (
          <Textbox
            key={fieldKey}
            type="text"
            value={value || ''}
            field={field}
            showLabel={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeTodoInput(fieldKey, e.target.value)
            }
            disabled={true}
          />
        );
      case 'number':
        return (
          <Textbox
            key={fieldKey}
            type="number"
            value={value || ''}
            field={field}
            showLabel={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeTodoInput(fieldKey, e.target.value)
            }
            disabled={true}
          />
        );
      case 'Timestamp':
        if (!value) {
          onChangeTodoInput(fieldKey, moment());
        }
        return (
          <DatePicker
            key={fieldKey}
            field={field}
            value={value || moment()}
            showLabel={false}
            onChange={(date: moment.Moment | null) => onChangeTodoInput(fieldKey, date)}
            disabled={true}
            withPortal={false}
          />
        );
      case 'currency':
        if (Number(value) > 0) {
          return <FormatCurrency key={fieldKey} value={value} />;
        } else {
          const inputField = { ...field };
          inputField.label = 'NA';
          return (
            <SwitchControl
              key={fieldKey + 'NA'}
              field={inputField}
              value={state.reason['collectedExcess'] === 'notApplicable'}
              formClass="todo-toggle-div"
              onChange={() => {}}
              disabled={true}
            />
          );
        }
      case 'boolean':
        return (
          <SwitchControl
            key={fieldKey}
            field={field}
            value={value || false}
            onChange={(checked: boolean) => onChangeTodoInput(fieldKey, checked)}
            disabled={true}
          />
        );
      case 'FileUpload':
        const hasFile = claim && claim.files && claim.files[field.key] ? true : false;
        if (hasFile && claim) {
          return (
            <FileSingle
              key={field.key}
              file={field.key}
              claim={claim}
              field={field}
              hideDelete={true}
            />
          );
        } else if (
          field.label === 'Calibration Certificate' &&
          state.reason['calibrationCertificate'] === 'notApplicable'
        ) {
          const inputField = { ...field };
          inputField.label = 'NA';
          return (
            <SwitchControl
              key={fieldKey + 'NA'}
              field={inputField}
              value={state.reason['calibrationCertificate'] === 'notApplicable'}
              formClass="todo-toggle-div"
              onChange={() => {}}
            />
          );
        } else if (field.label === 'Calibration Certificate') {
          const inputField = { ...field };
          inputField.label = 'NA';
          return (
            <>
              <SwitchControl
                key={fieldKey + 'NA'}
                field={inputField}
                value={state.reason['calibrationCertificate'] === 'notApplicable'}
                formClass="todo-toggle-div"
                onChange={() => {}}
                disabled={true}
              />
              <TodoFile
                loading={fileLoading}
                queue="outgoing"
                key={field.key}
                onDrop={(files: Array<{ file: string; ext: string }>) =>
                  onDropFile(todo, field, files)
                }
                onError={toast.error}
                className="todo-drop"
                field={field}
                hideText={true}
              />
            </>
          );
        } else {
          return (
            <TodoFile
              loading={fileLoading}
              queue="outgoing"
              key={field.key}
              onDrop={(files: Array<{ file: string; ext: string }>) =>
                onDropFile(todo, field, files)
              }
              onError={toast.error}
              className="todo-drop"
              field={field}
              hideText={true}
            />
          );
        }
      default:
        // no idea, so let's play it safe;
        console.error(
          `Invalid field control type ${field.control}, please check the type and try again`,
        );
        return false;
    }
  };

  const renderInputs = (todo: ITodo): ReactElement[] => {
    return _.compact(_.map(todo.fields, (field, fieldKey) => renderInput(todo, fieldKey, field)));
  };

  const renderTodo = (todo: ITodo, claim: IClaim): ReactElement | false => {
    if (!state.todos[todo.key]) {
      return false;
    }
    const isDisabled = true;
    return (
      <div className="claim-todo outgoing" key={`todo-${todo.key}`}>
        <Checkbox
          value={state.todos[todo.key].completed}
          onChange={_.noop}
          label={todo.label}
          disabled={isDisabled}
        />
        {!_.isEmpty(todo.fields) ? renderInputs(todo) : false}
      </div>
    );
  };

  if (!claim || _.isEmpty(state.todos)) {
    return <div></div>;
  }

  return (
    <div className="todo-list">
      <div className="files-attached-div">
        <h3>To Do List</h3>
        {claim.files && (
          <Button
            className="file-single"
            color="link"
            onClick={() => setFileModalOpen(true)}
            size="sm">
            <Icon icon={fileIcon} />
          </Button>
        )}
      </div>
      {_.map(todos, (todo) => renderTodo(todo, claim))}
      {claim.files && (
        <FilesViewModal
          onClose={() => {
            setFileModalOpen(false);
          }}
          isOpen={fileModalOpen}
          files={claim.files}
        />
      )}
    </div>
  );
};

export default connect(null, { uploadFile })(OutgoingTodoList);
