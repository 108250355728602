/* eslint-disable prettier/prettier */
import { functions } from '../../config/firebase';

export const downloadFiles = (
  file: Array<{ fileName: string; mimeType: string }>,
  callback: Function,
) => {
  return (dispatch: any) => {
    const callable = functions.httpsCallable('retrieveFile');
    callable({ file })
      .then((response: any) => {
        callback(null, response.data);
      })
      .catch((err) => {
        callback(err);
      });
  };
};

export const removeFiles = (key: string, id: string, callback: Function) => {
  return (dispatch: any) => {
    const callable = functions.httpsCallable('removeFiles');
    callable({ key, id })
      .then((response: any) => {
        callback(null, response.data);
      })
      .catch((err) => {
        callback(err);
      });
  };
};
