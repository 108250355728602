/* eslint-disable prettier/prettier */
import { Button } from 'reactstrap';
import { Icon } from 'react-icons-kit';
import { inbox } from 'react-icons-kit/feather';
import React from 'react';
import history from '../../../helpers/history';

const EmptyState: React.FC<EmptyStateProps> = ({ message }) => {
  return (
    <div className="empty-state">
      <Icon icon={inbox} size={64} />
      <h2>{message}</h2>
      <Button
        className="empty-state-back"
        outline
        onClick={() =>
          message === 'No claims match your search' ? window.location.reload() : history.push('/')
        }>
        Go Back
      </Button>
    </div>
  );
};

export default EmptyState;
