/* eslint-disable prettier/prettier */
import { Button } from 'reactstrap';
import { FileSingleProps } from '../../new/typings';
import { connect } from 'react-redux';
import { downloadFiles, removeFiles } from '../../../../actions/files';
import { eye, loader, trash2, x } from 'react-icons-kit/feather';
import { toast } from 'react-toastify';
import DeleteFilesModal from '../../../modals/DeleteFilesModal';
import Icon from 'react-icons-kit';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import Modal from 'react-modal';
import React, { ReactElement, useEffect, useState } from 'react';
import _ from 'lodash';

const initialState: { loading: boolean; loadingDelete: boolean } = {
  loading: false,
  loadingDelete: false,
};

const initialImages: ReactImageGalleryItem[] = [];

const FileSingle: React.FC<FileSingleProps> = ({
  claim,
  file,
  field,
  downloadFiles,
  removeFiles,
  hideDelete,
}) => {
  const [state, setState] = useState(initialState);
  const [isOpen, toggleModal] = useState(false);
  const [images, setImages] = useState(initialImages);
  const [keyToDelete, setKeyToDelete] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (keyToDelete !== '') {
      setModalOpen(true);
    }
  }, [keyToDelete]);

  if (!claim || !claim.files || !claim.files[file] || !file || !field) {
    return <div></div>;
  }

  const openFile = (pdf: string, mimeType: string) => {
    // base64 string

    // const base64str = await toBase64(pdf);

    const binaryString = atob(pdf);

    const binaryLen = binaryString.length;

    const bytes = new Uint8Array(binaryLen);

    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }

    // create the blob object with content-type "application/pdf"
    const blob = new Blob([bytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url);
  };

  const openPdf = async (files: {
    [index: number]: { data: any; mimeType: string };
  }): Promise<void> => {
    _.each(files, (file) => {
      openFile(file.data, file.mimeType);
    });
  };

  const openImageModal = (files: { data: string; mimeType: string }[]) => {
    const imgs: ReactImageGalleryItem[] = [];
    _.each(files, ({ data: file, mimeType }) => {
      imgs.push({
        original: `${mimeType},${file}`,
        thumbnail: `${mimeType},${file}`,
      });
    });
    setImages(imgs);
    toggleModal(true);
  };

  const download = (file: Array<{ fileName: string; mimeType: string }>, key: string) => {
    setState({ loading: true, loadingDelete: false });
    downloadFiles(file, (err: Error, files: any) => {
      setState({ loading: false, loadingDelete: false });
      if (err) {
        console.error(err);
        toast.error(`Could not retrieve ${key}, please try again later.`);
        return;
      }
      const imageFiles = files.filter((file: any) => {
        return !file?.mimeType.includes('pdf');
      });
      const pdfFiles = files.filter((file: any) => {
        return file?.mimeType.includes('pdf');
      });
      if (imageFiles && imageFiles.length > 0) {
        openImageModal(imageFiles);
      }
      if (pdfFiles && pdfFiles.length > 0) {
        openPdf(pdfFiles);
      }
    });
  };

  const deleteFiles = () => {
    if (keyToDelete !== '') {
      setState({ loading: false, loadingDelete: true });
      setModalOpen(false);
      removeFiles(keyToDelete, claim.id, (err: Error, files: any) => {
        setState({ loading: false, loadingDelete: false });
        if (err) {
          console.error(err);
          toast.error(`Could not retrieve ${keyToDelete}, please try again later.`);
          return;
        }
        toast.success('Files deleted successfully');
      });
    }
  };

  const showLoader = (): ReactElement | false => {
    if (!state.loading) {
      return <Icon icon={eye} size={16} />;
    }
    return <Icon icon={loader} size={16} className="spin" />;
  };

  const showLoaderTrash = (): ReactElement | false => {
    if (!state.loadingDelete) {
      return <Icon icon={trash2} size={16} />;
    }
    return <Icon icon={loader} size={16} className="spin" />;
  };

  const openDeleteFileModal = (key: string) => {
    setKeyToDelete(key);
  };

  const confirmCancel = () => {
    deleteFiles();
  };

  const rejectCancel = () => {
    setModalOpen(false);
    setKeyToDelete('');
  };

  return (
    <div>
      {modalOpen && (
        <DeleteFilesModal
          onConfirm={confirmCancel}
          onCancel={rejectCancel}
          isOpen={modalOpen}
          keyToDelete={keyToDelete}
        />
      )}
      <Modal isOpen={isOpen} className="image-modal" ariaHideApp={true}>
        <ImageGallery items={images} />
        <Button className="image-modal-close" onClick={() => toggleModal(!isOpen)}>
          <Icon icon={x} size={64} />
        </Button>
      </Modal>

      <div className="icons-div">
        <Button
          className="file-single"
          color="link"
          onClick={() => download(claim.files[file], file)}
          size="sm">
          {showLoader()}
        </Button>
        {!hideDelete && (
          <Button
            className="file-single trash"
            color="link"
            onClick={() => openDeleteFileModal(file)}
            size="sm">
            {showLoaderTrash()}
          </Button>
        )}
      </div>
    </div>
  );
};

export default connect(null, { downloadFiles, removeFiles })(FileSingle);
