/* eslint-disable prettier/prettier */
import { Button, Col, Collapse, Row } from 'reactstrap';
import { IClaim, OutgoingSidePanelProps } from '../typings';
import { cancelClaim, downloadClaimInfo, uploadFile } from '../../../actions/update-claim';
import { chevronDown, chevronUp, loader, x } from 'react-icons-kit/feather';
import { connect } from 'react-redux';
import ClaimInfo from '../shared/ClaimInfo';
import Icon from 'react-icons-kit';
import NotesComponent from '../shared/Notes';
import OutgoingTodo from './todo/OutgoingTodo';
import React, { ReactElement, useState } from 'react';

const CompletedSidePanel: React.FC<OutgoingSidePanelProps> = ({
  claim,
  todos,
  partner,
  onClose,
  uploadFile,
  cancelClaim,
}) => {
  const [editOpen, setEditOpen] = useState(false);
  const [chatOpen, setChatOpen] = useState(true);
  const [downloadInfoLoading, setDownloadInfoLoading] = useState(false);
  const [downloadPowLoading, setDownloadPowLoading] = useState(false);

  const collapseConfig = {
    show: 100,
    hide: 100,
  };

  const openPdf = (base64str: string) => {
    // base64 string

    // decode base64 string, remove space for IE compatibility
    const binary = atob(base64str.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    // create the blob object with content-type "application/pdf"
    const blob = new Blob([view], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url);
    setDownloadInfoLoading(false);
    setDownloadPowLoading(false);
  };

  const downloadProofOfWork = async () => {
    try {
      setDownloadPowLoading(true);
      const pdfFile = await downloadClaimInfo(claim?.id || '', false);
      if (pdfFile) {
        openPdf(pdfFile);
      }
    } catch (e) {
      setDownloadPowLoading(false);
      console.error(e);
    }
  };

  const downloadInfo = async () => {
    try {
      setDownloadInfoLoading(true);
      const pdfFile = await downloadClaimInfo(claim?.id || '', true);
      if (pdfFile) {
        pdfFile.forEach((file: string) => {
          openPdf(file);
        });
      }
    } catch (e) {
      setDownloadInfoLoading(false);
      console.error(e);
    }
  };

  const renderClaim = (claim: IClaim): ReactElement | false => {
    const template = partner.claimTemplate.claims;
    if (!claim) {
      return false;
    }
    return (
      <div>
        <div className="header-panel">
          <div>
            <Button className="close-btn" onClick={(e) => onClose(e)}>
              <Icon icon={x} size={28} />
            </Button>
            <h2>{claim?.insurer?.claimNumber}</h2>
          </div>
          <div>
            {!claim.active && claim?.status === 'COMPLETED' ? (
              <Button className="download-btn" outline size="sm" onClick={downloadProofOfWork}>
                {downloadPowLoading ? 'Downloading...' : 'Proof Of Work'}
                {downloadPowLoading && <Icon icon={loader} size={16} className="spin" />}
              </Button>
            ) : null}
            <Button className="download-btn" outline size="sm" onClick={downloadInfo}>
              {downloadInfoLoading ? 'Downloading...' : 'Summary'}
              {downloadInfoLoading && <Icon icon={loader} size={16} className="spin" />}
            </Button>
          </div>
        </div>
        <Row className="claim">
          <Col sm={6} className="todo-list-container">
            <div className="static">
              <OutgoingTodo claim={claim} todos={todos} />
            </div>
          </Col>
          <Col sm={6}>
            <div>
              <Button
                className="accordion-btn"
                outline={editOpen ? false : true}
                size="sm"
                onClick={() => {
                  setEditOpen(!editOpen);
                  setChatOpen(!chatOpen);
                }}>
                <div>
                  Claim Info <Icon icon={editOpen ? chevronUp : chevronDown} size={20} />{' '}
                </div>
              </Button>
              <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={editOpen}>
                <ClaimInfo queue="completed" groups={template} claim={claim} />
              </Collapse>
            </div>
            <div>
              <Button
                className="accordion-btn"
                outline={chatOpen ? false : true}
                size="sm"
                onClick={() => {
                  setChatOpen(!chatOpen);
                  setEditOpen(!editOpen);
                }}>
                <div>
                  Claim Notes <Icon icon={chatOpen ? chevronUp : chevronDown} size={20} />{' '}
                </div>
              </Button>
              <Collapse delay={collapseConfig} isOpen={chatOpen}>
                <div className="static">
                  <NotesComponent claim={claim} />
                </div>
              </Collapse>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  if (!claim) {
    return <div></div>;
  }

  return (
    <div>
      <div className={`side-panel-overlay ${claim ? 'open' : ''}`}></div>
      <div id="incoming-side-panel" className={`side-panel ${claim ? 'open' : ''}`}>
        {claim ? renderClaim(claim) : false}
      </div>
    </div>
  );
};

export default connect(null, { cancelClaim, uploadFile })(CompletedSidePanel);
