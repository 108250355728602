/* eslint-disable prettier/prettier */
export interface ISearchClaimReducerState {
  searchString: string;
}

const INIT: ISearchClaimReducerState = {
  searchString: '',
};

export default (state = INIT, action: any) => {
  switch (action.type) {
    case 'SEARCH_CHANGE':
      return {
        ...state,
        searchString: action.value,
      };
    default:
      return { ...state };
  }
};
