/* eslint-disable prettier/prettier */
import { FileDropZone } from './FileDropzone';
import { TodoFileProps } from '../../new/typings';
import React, { ReactElement } from 'react';

import _ from 'lodash';

const TodoFile: React.FC<TodoFileProps> = (props): ReactElement => {
  const { onDrop, field, queue } = props;
  const onDropFiles = (files: Array<{ file: string; ext: string }>) => {
    onDrop(files);
  };
  if (queue && field && field.queue && !_.includes(field.queue, queue)) {
    return <></>;
  }
  return <FileDropZone {...props} onDrop={onDropFiles} />;
};

export default TodoFile;
