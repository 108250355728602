/* eslint-disable prettier/prettier */
import { Badge, Button, Col, Collapse, Row, Table } from 'reactstrap';
import { IClaim, OutgoingSidePanelProps } from '../typings';
import { cancelClaim, uploadFile } from '../../../actions/update-claim';
import { chevronDown, chevronUp, x } from 'react-icons-kit/feather';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import CancelModal from '../../modals/CancelModal';
import ClaimInfoEdit from '../shared/ClaimInfoEdit';
import Icon from 'react-icons-kit';
import NotesComponent from '../shared/Notes';
import OutgoingTodo from '../side-panel/todo/OutgoingTodo';
import React, { ReactElement, useState } from 'react';
import _ from 'lodash';

const initialState: { loading: boolean } = {
  loading: false,
};

const OutgoingSidePanel: React.FC<OutgoingSidePanelProps> = ({
  claim,
  todos,
  partner,
  onClose,
  uploadFile,
  cancelClaim,
}) => {
  const [state, setState] = useState(initialState);
  const [modalOpen, setModalOpen] = useState(false);
  const [reason, setReason] = useState('');
  const [editOpen, setEditOpen] = useState(false);
  const [chatOpen, setChatOpen] = useState(true);

  const collapseConfig = {
    show: 100,
    hide: 100,
  };

  const cancel = (e: any) => {
    if (!claim) {
      return false;
    }
    setState({ loading: true });
    cancelClaim(claim.id, reason, 'origin', (err: Error) => {
      setState({ loading: false });
      if (err) {
        console.error(err);
        toast.error('Could not cancel claim, please try again later.');
        return;
      }
      onClose(e);
    });
  };

  const confirmCancel = () => {
    setModalOpen(false);
    cancel(false);
  };

  const rejectCancel = () => {
    setModalOpen(false);
  };

  const showModal = () => {
    setModalOpen(true);
  };

  const determineCurrentStep = () => {
    if (!todos || !claim) {
      return null;
    }
    let currentTodo = _.first(todos);
    try {
      _.each(todos, (todo, index) => {
        const isNext =
          (claim.todos[todo?.key] ? claim.todos[todo?.key].completed === false : false) ||
          _.isUndefined(claim.todos[todo?.key]);
        if (isNext) {
          const nextIndex = Number(index);
          currentTodo = todos[nextIndex];
          return false;
        }
        return true;
      });
    } catch (e) {
      console.error(e);
    }
    return currentTodo;
  };

  const disableCancelButton = (): boolean => {
    const currentStep = determineCurrentStep();
    const shouldDisable = currentStep?.allowCancel === false;
    return shouldDisable;
  };

  const onChangeReason = (value: string) => {
    setReason(value);
  };

  const renderClaim = (claim: IClaim): ReactElement | false => {
    const template = partner.claimTemplate.claims;
    if (!claim) {
      return false;
    }
    return (
      <div>
        <div className="header-panel">
          <div>
            <Button className="close-btn" onClick={(e) => onClose(e)}>
              <Icon icon={x} size={28} />
            </Button>
            <h2>
              {claim?.insurer?.claimNumber}
              <Badge className="status-badge">{claim?.status}</Badge>
            </h2>
          </div>
        </div>
        <Row className="claim">
          <Col sm={6} className="todo-list-container">
            <div className="static">
              <OutgoingTodo claim={claim} todos={todos} />
            </div>
            <div className="edit-buttons">
              {!disableCancelButton() ? (
                <Button size="sm" className="cancel-claim" outline onClick={showModal}>
                  {state.loading ? 'Cancelling...' : 'Cancel Claim'}
                </Button>
              ) : null}
            </div>
          </Col>
          <Col sm={6}>
            <div>
              <Button
                className="accordion-btn"
                outline={editOpen ? false : true}
                size="sm"
                onClick={() => {
                  setEditOpen(!editOpen);
                  setChatOpen(!chatOpen);
                }}>
                <div>
                  Claim Info <Icon icon={editOpen ? chevronUp : chevronDown} size={20} />{' '}
                </div>
              </Button>
              <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={editOpen}>
                <ClaimInfoEdit
                  queue="destination"
                  groups={template}
                  claim={claim}
                  isDisabled={false}
                />
                <div className="scrollbar-container sidebar-info-scrollbar ps">
                  <div className="claim-group">
                    <div className="claim-group-lebal" style={{ fontSize: '1.20em', fontWeight: 'bold', margin: '0px 0px 0px 10px'}}>
                      <div>Claim Originator</div>
                    </div>
                    <Table className="sidepanel-info-table table table-borderless">
                      <colgroup>
                        <col width="30%" />
                        <col width="70%" />
                      </colgroup>
                      <tbody>
                        <tr>
                        <div className="form-group"></div>
                        </tr>
                        <tr>
                          <td>Created By</td>
                          <td>
                            <div className="form-group">
                              <div>
                                {claim?.assign?.origin}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
                &nbsp;
              </Collapse>
            </div>
            <div>
              <Button
                className="accordion-btn"
                outline={chatOpen ? false : true}
                size="sm"
                onClick={() => {
                  setChatOpen(!chatOpen);
                  setEditOpen(!editOpen);
                }}>
                <div>
                  Claim Notes <Icon icon={chatOpen ? chevronUp : chevronDown} size={20} />{' '}
                </div>
              </Button>

              <Collapse delay={collapseConfig} isOpen={chatOpen}>
                <div className="static">
                  <NotesComponent claim={claim} />
                </div>
              </Collapse>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  if (!claim) {
    return <div></div>;
  }

  return (
    <div>
      <CancelModal
        claim={claim}
        onConfirm={confirmCancel}
        onCancel={rejectCancel}
        isOpen={modalOpen}
        reason={reason}
        onChangeReason={onChangeReason}
      />
      <div className={`side-panel-overlay ${claim ? 'open' : ''}`}></div>
      <div id="incoming-side-panel" className={`side-panel ${claim ? 'open' : ''}`}>
        {claim ? renderClaim(claim) : false}
      </div>
    </div>
  );
};

export default connect(null, { cancelClaim, uploadFile })(OutgoingSidePanel);
