/* eslint-disable prettier/prettier */
import { Button, ModalBody, ModalHeader } from 'reactstrap';
import { ConfirmModalProps } from '../claims/new/typings';
import { Icon } from 'react-icons-kit';
import { alertTriangle } from 'react-icons-kit/feather';
import Modal from 'react-modal';
import React from 'react';

const DeleteFilesModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  onConfirm,
  onCancel,
  keyToDelete,
}) => {
  let flow = '';
  switch (keyToDelete) {
    case 'invoice':
      flow = 'Invoice';
      break;
    case 'vehicleImages':
      flow = 'Vehicle Images';
      break;
    case 'calibrationCertificate':
      flow = 'Calibration Certificate';
      break;
    case 'proofOfPayment':
      return 'Proof of Payment -';
    default:
      break;
  }
  return (
    <div>
      <Modal isOpen={isOpen} className="cancel-claim-modal" ariaHideApp={false}>
        <ModalHeader>Delete Claim Files</ModalHeader>
        <ModalBody>
          <p>Clicking on delete will remove all files associated to {flow}</p>
          <p>
            <strong>Are you sure you wish to cancel this claim?</strong>
          </p>
          <div className="cancel-modal-buttons">
            <Button size="sm" color="secondary" block onClick={() => onCancel()}>
              No, Return To Claim
            </Button>
            <Button size="sm" color="danger" block outline onClick={() => onConfirm()}>
              <Icon icon={alertTriangle} tag="div" className="btn-icon" /> Yes, Delete Files
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DeleteFilesModal;
