/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import { DropdownOptionYear, YearInputProps } from '../../typings';
import { FormGroup, Label } from 'reactstrap';
import React, { ReactElement, useState } from 'react';
import Select, { ValueType } from 'react-select';
import _ from 'lodash';

const year = new Date().getFullYear();
const arraySize = year - 1904;
const years = Array.from(new Array(arraySize), (val, index) => Math.abs(index - year));
const options: DropdownOptionYear[] = _.map(years, (year) => {
  return { value: year, label: String(year) };
});

const YearInput: React.FC<YearInputProps> = ({
  field,
  value,
  onChange,
  showLabel = true,
  disabled = false,
}) => {
  const [touched, setTouched] = useState(false);

  const renderLabel = (): ReactElement | false => {
    if (showLabel) {
      return (
        <Label for="exampleEmail">
          <div>
            {field.label}
            {field.required ? <span className="text-danger">*</span> : false}
          </div>
        </Label>
      );
    }
    return false;
  };

  const onChangeDropdown = (selectedOption: ValueType<DropdownOptionYear>) => {
    setTouched(true);
    const selectedValue: number = (selectedOption as unknown as DropdownOptionYear).value;
    return onChange(String(selectedValue));
  };

  const defaultedValue = Number(value) || new Date().getFullYear();

  const selected: ValueType<DropdownOptionYear> = {
    value: defaultedValue,
    label: String(defaultedValue),
  };

  const isValid = touched === true;
  const validationClass = isValid ? 'is-valid' : 'invalid';

  return (
    <FormGroup className={validationClass}>
      {renderLabel()}
      <Select
        className={`${disabled ? 'select-dropdown-disabled' : 'select-dropdown'}`}
        options={options}
        value={selected}
        isDisabled={disabled}
        onChange={onChangeDropdown}
      />
    </FormGroup>
  );
};

export default YearInput;
