/* eslint-disable prettier/prettier */
export const UPDATE_CLAIM = 'UPDATE_CLAIM';
export const UPDATE_CLAIM_FAIL = 'UPDATE_CLAIM_FAIL';
export const UPDATE_CLAIM_IN_PROGRESS = 'UPDATE_CLAIM_IN_PROGRESS';

export const UPLOAD_INVOICE = 'UPLOAD_INVOICE';
export const UPLOAD_INVOICE_FAIL = 'UPLOAD_INVOICE_FAIL';
export const UPLOAD_INVOICE_IN_PROGRESS = 'UPLOAD_INVOICE_IN_PROGRESS';

export const ADD_CLAIM_NOTE = 'ADD_CLAIM_NOTE';
export const ADD_CLAIM_NOTE_FAIL = 'ADD_CLAIM_NOTE_FAIL';
export const ADD_CLAIM_NOTE_IN_PROGRESS = 'ADD_CLAIM_NOTE_IN_PROGRESS';
