/* eslint-disable prettier/prettier */
import _ from 'lodash';

const replaceTimeStampWithDate = (item: any, key: any) => {
  if (_.isDate(item)) {
    return item.toISOString();
  }
  return item;
};

export const convertFirebaseDates = (row: any) => {
  const newRow: any = {};
  _.each(row, (item: any, key: any) => {
    if (_.isObject(item) && !_.isArray(item) && !_.isDate(item) && !item.hasOwnProperty('$date')) {
      newRow[key] = _.mapValues(item, (subItem: any, subKey: any) => {
        return replaceTimeStampWithDate(subItem, subKey);
      });
    } else {
      newRow[key] = replaceTimeStampWithDate(item, key);
    }
  });
  return newRow;
};
