/* eslint-disable prettier/prettier */
import { FormNavItemProps } from '../typings';
import { NavItem, NavLink } from 'reactstrap';
import React from 'react';
import classnames from 'classnames';

const FormNavItem: React.FC<FormNavItemProps> = ({ active, valid, ready, group, id, toggle }) => {
  return (
    <NavItem key={`form-nav-${id}`}>
      <NavLink
        className={classnames({
          active: active === true,
          invalid: valid === false,
          ready: ready === true,
        })}
        onClick={() => {
          toggle(String(id));
        }}>
        {group.label}
      </NavLink>
    </NavItem>
  );
};

export default FormNavItem;
