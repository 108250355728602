/* eslint-disable prettier/prettier */
import { ActionWithAuth, ActionWithPayload, ActionWithProfile } from '../../typings';
import {
  CURRENCY_CHANGE_OBJ,
  CURRENCY_UPDATE_VALUE,
  NC_INIT_VALUE,
  NC_RESET_CLAIM,
  NC_UPDATE_VALUE,
} from '../../actions/new-claim/types';
import { IClaimGroup, IClaimGroupField } from '../../components/claims/new/typings';
import { INewClaimPayload } from '../../../../typings';
import { INewClaimReducer } from './typings';
import { actionTypes as firebaseActionTypes } from 'react-redux-firebase';
import { validateEmail } from '../../helpers/utils';
import _ from 'lodash';
import moment from 'moment';

const defaultCurrency = { label: 'EUR', value: 'EUR' };

const validatePayload = (
  payload: INewClaimPayload,
  validationGroup: IClaimGroup,
): { [key: string]: string[] } => {
  const invalid: { [key: string]: string[] } = {};
  const validationGroupFields: IClaimGroupField[] = validationGroup.fields;
  _.each(validationGroupFields, (field) => {
    const payloadEntryGroup: any = payload[validationGroup.key];
    const payloadEntry = payloadEntryGroup[field.key];
    if (field.required === true) {
      if (!_.isBoolean(payloadEntry) && !payloadEntry) {
        invalid[validationGroup.key] = _.isArray(invalid[validationGroup.key])
          ? invalid[validationGroup.key]
          : [];
        invalid[validationGroup.key] = [...invalid[validationGroup.key], field.key];
      } else if (field.type === 'Date' && !_.isDate(payloadEntry)) {
        invalid[validationGroup.key] = _.isArray(invalid[validationGroup.key])
          ? invalid[validationGroup.key]
          : [];
        invalid[validationGroup.key] = [...invalid[validationGroup.key], field.key];
      } else if (field.type !== 'Date' && typeof payloadEntry !== field.type) {
        invalid[validationGroup.key] = _.isArray(invalid[validationGroup.key])
          ? invalid[validationGroup.key]
          : [];
        invalid[validationGroup.key] = [...invalid[validationGroup.key], field.key];
      } else if (field.control === 'email' && !validateEmail(payloadEntry)) {
        invalid[validationGroup.key] = _.isArray(invalid[validationGroup.key])
          ? invalid[validationGroup.key]
          : [];
        invalid[validationGroup.key] = [...invalid[validationGroup.key], field.key];
      }
    }
  });
  return invalid;
};

const validateEmpty = (valid: string[], empty: string[]): string[] => {
  const emptyItems = _.remove(empty, (e) => !_.includes(valid, e));
  return emptyItems;
};

const INIT: INewClaimReducer = {
  valid: [],
  invalid: {},
  empty: ['driver', 'vehicle', 'repairInfo', 'insurer', 'assign'],
  claim: {
    driver: {
      name: null,
      surname: null,
      mobileNumber: null,
      homeNumber: null,
      workNumber: null,
      email: null,
      homeAddress: null,
    },
    vehicle: {
      make: null,
      model: null,
      year: null,
      registrationNumber: null,
      vinNumber: null,
    },
    repairInfo: {
      incidentType: null,
      glassPosition: null,
      urgency: null,
      causeOfLoss: null,
      incidentDate: moment().toDate(),
      preferredRepairDate: moment().toDate(),
      preferredRepairAddress: null,
    },
    insurer: {
      name: null,
      policyNumber: null,
      claimNumber: null,
      authorized: false,
      authorizedValue: null,
      excessValue: null,
    },
    assign: {
      partner: null,
      origin: null,
      owners: {
        partner: null,
        origin: null,
      },
      jobCostEstimate: null,
      notes: null,
      attachments: [],
    },
  },
  currencyObj: {},
  currencyChangeObj: {},
};

export default function (state = { ...INIT }, action: ActionWithPayload) {
  switch (action.type) {
    case firebaseActionTypes.SET_PROFILE:
      const firebaseProfileAction = action as unknown as ActionWithProfile;
      return {
        ...state,
        claim: {
          ...state.claim,
          assign: {
            ...state.claim.assign,
            origin: firebaseProfileAction.profile.partnerId,
          },
        },
      };
    case firebaseActionTypes.LOGIN:
      const firebaseAuthAction = action as unknown as ActionWithAuth;
      return {
        ...state,
        claim: {
          ...state.claim,
          assign: {
            ...state.claim.assign,
            owners: {
              ...state.claim.assign.owners,
              origin: firebaseAuthAction.auth.uid,
            },
          },
        },
      };

    case NC_UPDATE_VALUE:
      const {
        group,
        key,
        value,
        validation,
      }: {
        group: keyof INewClaimPayload;
        key: string;
        value: any;
        validation: IClaimGroup;
      } = action.payload;
      const claim: INewClaimPayload = {
        ...state.claim,
        [group]: {
          ...state.claim[group],
          [key]: value,
        },
      };
      const invalidItems = validatePayload(claim, validation);
      const invalidRaw = {
        ...state.invalid,
        [group]: invalidItems[group],
      };

      const invalid: { [key: string]: string[] } = _.omitBy(invalidRaw, (i) => _.isEmpty(i)) as {
        [key: string]: string[];
      };
      const validGroup: string[] = _.uniq(
        _.compact(_.map(invalidRaw, (i, k) => (_.isEmpty(i) ? k : null))),
      );
      const valid = _.uniq([...state.valid, ...validGroup]);
      const empty = validateEmpty(valid, state.empty);

      return {
        ...state,
        valid: valid,
        invalid: invalid,
        claim: claim,
        empty: empty,
      };

    case CURRENCY_UPDATE_VALUE:
      const input: { key: string; value: any } = action.payload as {
        [key: string]: string[];
      };
      return {
        ...state,
        currencyObj: {
          ...state.currencyObj,
          [input.key]: input.value,
        },
      };

    case CURRENCY_CHANGE_OBJ:
      return {
        ...state,
        currencyChangeObj: action.payload,
      };

    case NC_INIT_VALUE:
      const payload: {
        group: keyof INewClaimPayload;
        key: string;
        value: any;
        validation: IClaimGroup;
      } = action.payload;
      const newClaim: INewClaimPayload = {
        ...state.claim,
        [payload.group]: {
          ...state.claim[payload.group],
          [payload.key]: payload.value,
        },
      };
      return {
        ...state,
        claim: newClaim,
        currencyObj: {
          authorizedValue: defaultCurrency,
          excessValue: defaultCurrency,
          jobCostEstimate: defaultCurrency,
        },
        currencyChangeObj: {},
      };

    case NC_RESET_CLAIM:
      return {
        ...state,
        ...INIT,
      };

    default:
      return { ...state };
  }
}
