/* eslint-disable prettier/prettier */
import { Badge, FormGroup, Label } from 'reactstrap';
import { UrgencyProps } from '../../typings';
import React, { useEffect } from 'react';
import _ from 'lodash';

const Urgency: React.FC<UrgencyProps> = ({ field, claim, value = false, onChange }) => {
  const {
    claim: {
      repairInfo: { incidentType, glassPosition },
    },
  } = claim;
  const urgencyLogic = field.urgency;
  let isUrgent = false;
  if (incidentType && glassPosition) {
    isUrgent = _.some(urgencyLogic, (item) => {
      const repairTypes = _.map(item.repairType, (i) => i.toLowerCase());
      const glassPositions = _.map(item.glassPosition, (i) => i.toLowerCase());
      const result =
        _.includes(repairTypes, incidentType.toLowerCase()) &&
        _.includes(glassPositions, glassPosition.toLowerCase());
      return result;
    });
  }
  const urgency = isUrgent ? 'Urgent' : 'Non-Urgent';
  const hasChanged = isUrgent !== value;
  useEffect(() => {
    onChange(isUrgent);
    // we don't need exhaustive hook dep check, shut the linter up.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChanged]);
  return (
    <FormGroup>
      <Label>{field.label}</Label>
      <Badge color={isUrgent ? 'danger' : 'warning'}>{urgency}</Badge>
    </FormGroup>
  );
};

export default Urgency;
