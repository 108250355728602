/* eslint-disable prettier/prettier */
import { ClaimInfoProps, IClaimGroup, IClaimGroupField } from '../new/typings';
import { IClaim } from '../typings';
import { Table } from 'reactstrap';
import { isIsoDate } from '../../../helpers/utils';
import React, { ReactElement } from 'react';
import _ from 'lodash';
import moment from 'moment';

const ClaimInfo: React.FC<ClaimInfoProps> = ({ groups, claim, queue }) => {
  if (!claim) {
    return null;
  }
  const renderRow = (field: IClaimGroupField, value: any, index: any): ReactElement | false => {
    if (!field.visible || (_.isArray(field.visible) && !_.includes(field.visible, queue))) {
      return false;
    }
    const val =
      (_.isObjectLike(value) && _.isFunction(value.toDate)) || isIsoDate(value)
        ? moment(value).format('DD MMM, YYYY')
        : value;
    return (
      <tr key={`${index}`}>
        <td>{String(field.label)}</td>
        <td>{val ? String(val) : '-'}</td>
      </tr>
    );
  };

  const renderClaimGroup = (group: IClaimGroup, claim: IClaim): ReactElement | false => {
    const claimGroup = _.compact(
      _.map(group.fields, (field: IClaimGroupField, index: string) => {
        // Cast as any so we can access prop without implicit any error
        const claimGroup = claim[group.key] as any;
        const value = claimGroup[field.key];
        return renderRow(field, value, index);
      }),
    );
    if (_.isEmpty(claimGroup)) {
      return false;
    }
    return (
      <div className="claim-group" key={group.key}>
        <div className="claim-group-label">
          <div>{group.label}</div>
        </div>
        <Table borderless className="sidepanel-info-table">
          <colgroup>
            <col width="30%" />
            <col width="70%" />
          </colgroup>
          <tbody>{_.map(claimGroup)}</tbody>
        </Table>
      </div>
    );
  };

  return (
    <div className="sidebar-info-scrollbar max-height-sidebar info-only">
      {_.map(groups, (group: IClaimGroup) => renderClaimGroup(group, claim))}
    </div>
  );
};

export default ClaimInfo;
