/* eslint-disable prettier/prettier */
import { EmailInputProps } from '../../typings';
import { FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import { Icon } from 'react-icons-kit';
import { mail } from 'react-icons-kit/feather';
import { validateEmail } from '../../../../../helpers/utils';
import React, { ReactElement, useState } from 'react';
import _ from 'lodash';

const EmailInput: React.FC<EmailInputProps> = ({
  field,
  type,
  placeholder,
  value,
  onChange,
  showLabel = true,
  disabled = false,
}) => {
  const [touched, setTouched] = useState(false);
  const renderLabel = (): ReactElement | false => {
    if (showLabel) {
      return (
        <Label>
          <div>
            {field.label}
            {field.required ? <span className="text-danger">*</span> : false}
          </div>
          {field.limit ? (
            <div className="field-limit-indicator">
              {_.size(value)}/{field.limit}
            </div>
          ) : (
            false
          )}
        </Label>
      );
    }
    return false;
  };

  const onChangeInput = (event: React.ChangeEvent<any>) => {
    setTouched(true);
    onChange(event);
  };

  const isValid = validateEmail(value);
  const validationClass = isValid ? 'is-valid' : 'invalid';
  const formGroupClass = touched ? validationClass : '';

  const renderInput = () => {
    return (
      <Input
        accept="email"
        type="email"
        name="email"
        value={value}
        placeholder={placeholder}
        onChange={onChangeInput}
        disabled={disabled}
        maxLength={field.limit}
      />
    );
  };

  const renderWithPrefix = () => {
    return (
      <InputGroup className={`${disabled ? `disabled ${formGroupClass}` : formGroupClass}`}>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <Icon icon={mail} />
          </InputGroupText>
        </InputGroupAddon>
        {renderInput()}
      </InputGroup>
    );
  };

  return (
    <FormGroup>
      {renderLabel()}
      {renderWithPrefix()}
    </FormGroup>
  );
};

export default EmailInput;
