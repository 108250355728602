/* eslint-disable prettier/prettier */
import { Button, Table } from 'reactstrap';
import { ConfirmModalProps } from '../claims/new/typings';
import Modal from 'react-modal';
import React from 'react';

const ConfirmModal: React.FC<ConfirmModalProps> = ({ claim, isOpen, onConfirm, onCancel }) => {
  return (
    <div>
      <Modal isOpen={isOpen} className="confirm-claim-modal" ariaHideApp={false}>
        <h2>New Claim</h2>
        <p>
          I hereby confirm that all details captured by me in this claim are accurate and to the
          best of my knowledge, true.
        </p>
        <p>
          <b>
            All the costs are converted to EURO, please do check the conversion and confirm if the
            values are correct
          </b>
        </p>
        <Table borderless>
          <tbody>
            <tr>
              <td className="confirm-modal-label">Driver E-mail</td>
              <td>{claim?.driver.email}</td>
            </tr>
            <tr>
              <td className="confirm-modal-label">Vehicle</td>
              <td>
                {claim?.vehicle.make} {claim?.vehicle.model}
              </td>
            </tr>
            <tr>
              <td className="confirm-modal-label">Type</td>
              <td>{claim?.repairInfo.incidentType}</td>
            </tr>
            <tr>
              <td className="confirm-modal-label">Position</td>
              <td>{claim?.repairInfo.glassPosition}</td>
            </tr>
            <tr>
              <td className="confirm-modal-label">Insurance</td>
              <td>{claim?.insurer.name}</td>
            </tr>
            <tr>
              <td className="confirm-modal-label">Cost Estimate</td>
              <td>€{claim?.assign.jobCostEstimate}</td>
            </tr>
            <tr>
              <td className="confirm-modal-label">Authorised</td>
              <td>€{claim?.insurer.authorizedValue}</td>
            </tr>
            <tr>
              <td className="confirm-modal-label">Excess</td>
              <td>€{claim?.insurer.excessValue}</td>
            </tr>
            <tr>
              <td className="confirm-modal-label">Sending To</td>
              <td>{claim?.assign.partner}</td>
            </tr>
          </tbody>
        </Table>
        <div className="confirm-modal-buttons">
          <Button color="secondary" outline onClick={() => onCancel()}>
            No, Go Back to Claim
          </Button>
          <Button color="secondary" onClick={() => onConfirm()}>
            Yes, Create Claim
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
